import { TranscriptPostPayload } from "@/app/types/transcripts";
import { supabase } from "./supabase_instance";

type Options = TranscriptPostPayload;
export const createTranscript = async ({
  url,
  sourceLang,
}: Options): Promise<string> => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
  const match = regex.exec(url);
  if (!match) {
    alert("Ungültige URL");
    return;
  }

  const { data, error } = await supabase.functions.invoke("createTranscript", {
    body: { url: url, sourceLang: sourceLang },
  });

  if (error) throw error;

  const transcript_id = data?.id;
  if (!transcript_id) throw new Error("Something went wrong...");

  return transcript_id;
};

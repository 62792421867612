import React from "react";
import { Link } from "react-router-dom";

export const DataProtection = () => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-4xl font-bold mb-6">Datenschutz</h1>
      <h2 className="text-2xl mt-4" id="einleitung-ueberblick">
        Einleitung und Überblick
      </h2>
      <p>
        Wir haben diese Datenschutzerklärung (Fassung 29.01.2023) verfasst, um
        Ihnen gemäß der Vorgaben der{" "}
        <Link
          to="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=312394123#d1e2269-1-1"
          target="_blank"
          rel="noopener"
        >
          Datenschutz-Grundverordnung (EU) 2016/679
        </Link>{" "}
        und anwendbaren nationalen Gesetzen zu erklären, welche
        personenbezogenen Daten (kurz Daten) wir als Verantwortliche – und die
        von uns beauftragten Auftragsverarbeiter (z. B. Provider) – verarbeiten,
        zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie
        haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.
      </p>
      <p>
        <strong>Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten,
        die wir über Sie verarbeiten.
      </p>
      <p>
        Datenschutzerklärungen klingen für gewöhnlich sehr technisch und
        verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll
        Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie
        möglich beschreiben. Soweit es der Transparenz förderlich ist, werden
        technische <strong>Begriffe leserfreundlich erklärt</strong>, Links zu
        weiterführenden Informationen geboten und <strong>Grafiken</strong> zum
        Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache,
        dass wir im Rahmen unserer Geschäftstätigkeiten nur dann
        personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche
        Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst
        knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie
        im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe,
        Sie finden die folgenden Erläuterungen interessant und informativ und
        vielleicht ist die eine oder andere Information dabei, die Sie noch
        nicht kannten.
        <br />
        Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten
        bzw. im{" "}
        <Link to="/impressum" data-v-f58a9f50>
          {" "}
          Impressum
        </Link>{" "}
        genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu
        folgen und sich weitere Informationen auf Drittseiten durchzulesen.
        Unsere Kontaktdaten finden Sie selbstverständlich auch im{" "}
        <Link to="/impressum" data-v-f58a9f50>
          Impressum
        </Link>{" "}
        .
      </p>
      <h2 className="text-2xl mt-4" id="anwendungsbereich">
        Anwendungsbereich
      </h2>
      <p>
        Diese Datenschutzerklärung gilt für alle von uns im Unternehmen
        verarbeiteten personenbezogenen Daten und für alle personenbezogenen
        Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten.
        Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4
        Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische
        Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt
        dafür, dass wir unsere Dienstleistungen und Produkte anbieten und
        abrechnen können, sei es online oder offline. Der Anwendungsbereich
        dieser Datenschutzerklärung umfasst:
      </p>
      <ul>
        <li>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
        <li>Social Media Auftritte und E-Mail-Kommunikation</li>
        <li>mobile Apps für Smartphones und andere Geräte</li>
      </ul>
      <p>
        <strong>Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle
        Bereiche, in denen personenbezogene Daten im Unternehmen über die
        genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb
        dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie
        gegebenenfalls gesondert informieren.
      </p>
      <h2 className="text-2xl mt-4" id="rechtsgrundlagen">
        Rechtsgrundlagen
      </h2>
      <p>
        In der folgenden Datenschutzerklärung geben wir Ihnen transparente
        Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den
        Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen,
        personenbezogene Daten zu verarbeiten.
        <br />
        Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU)
        2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016.
        Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich
        online auf EUR-Lex, dem Zugang zum EU-Recht, unter{" "}
        <Link to="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">
          https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679
        </Link>{" "}
        nachlesen.
      </p>
      <p>
        Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden
        Bedingungen zutrifft:
      </p>
      <ol>
        <li>
          <strong>Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie
          haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck
          zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen
          Daten eines Kontaktformulars.
        </li>
        <li>
          <strong>Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen
          Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen,
          verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag
          mit Ihnen abschließen, benötigen wir vorab personenbezogene
          Informationen.
        </li>
        <li>
          <strong>Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c
          DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen,
          verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich
          verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese
          enthalten in der Regel personenbezogene Daten.
        </li>
        <li>
          <strong>Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f
          DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht
          einschränken, behalten wir uns die Verarbeitung personenbezogener
          Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um
          unsere Website sicher und wirtschaftlich effizient betreiben zu
          können. Diese Verarbeitung ist somit ein berechtigtes Interesse.
        </li>
      </ol>
      <p>
        Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen
        Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz
        lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit
        eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an
        der entsprechenden Stelle ausgewiesen.
      </p>
      <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
      <ul>
        <li>
          In <strong>Österreich</strong> ist dies das Bundesgesetz zum Schutz
          natürlicher Personen bei der Verarbeitung personenbezogener Daten (
          <strong>Datenschutzgesetz</strong>), kurz <strong>DSG</strong>.
        </li>
        <li>
          In <strong>Deutschland</strong> gilt das
          <strong>Bundesdatenschutzgesetz</strong>, kurz<strong> BDSG</strong>.
        </li>
      </ul>
      <p>
        Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen,
        informieren wir Sie in den folgenden Abschnitten darüber.
      </p>
      <h2 className="text-2xl mt-4" id="kontaktdaten-verantwortliche">
        Kontaktdaten des Verantwortlichen
      </h2>
      <p>
        Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung
        personenbezogener Daten haben, finden Sie nachfolgend die Kontaktdaten
        der verantwortlichen Person bzw. Stelle:
      </p>
      <p className="mt-2">Jannis Rosenbaum</p>
      <p>
        dock51 UG (haftungsbeschränkt)
        <br />
        Eichendorffstr. 38
        <br />
        24116 Kiel Deutschland
      </p>
      <p>E-Mail: impressum &lt;ät&gt; dock51.de </p>
      <p>
        Impressum:{" "}
        <Link to="/impressum" data-v-f58a9f50>
          Zum Impressum
        </Link>{" "}
      </p>
      <h2 className="text-2xl mt-4" id="speicherdauer">
        Speicherdauer
      </h2>
      <p>
        Dass wir personenbezogene Daten nur so lange speichern, wie es für die
        Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig
        ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir
        personenbezogene Daten löschen, sobald der Grund für die
        Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir
        gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des
        ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der
        Buchführung.
      </p>
      <p>
        Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur
        Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und
        soweit keine Pflicht zur Speicherung besteht, gelöscht.
      </p>
      <p>
        Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir
        Sie weiter unten, sofern wir weitere Informationen dazu haben.
      </p>
      <h2 className="text-2xl mt-4" id="rechte-dsgvo">
        Rechte laut Datenschutz-Grundverordnung
      </h2>
      <p>
        Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden
        Rechte, die Ihnen zustehen, damit es zu einer fairen und transparenten
        Verarbeitung von Daten kommt:
      </p>
      <ul>
        <li>
          Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir
          Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht
          darauf eine Kopie der Daten zu erhalten und die folgenden
          Informationen zu erfahren:
          <ul>
            <li>zu welchem Zweck wir die Verarbeitung durchführen;</li>
            <li>
              die Kategorien, also die Arten von Daten, die verarbeitet werden;
            </li>
            <li>
              wer diese Daten erhält und wenn die Daten an Drittländer
              übermittelt werden, wie die Sicherheit garantiert werden kann;
            </li>
            <li>wie lange die Daten gespeichert werden;</li>
            <li>
              das Bestehen des Rechts auf Berichtigung, Löschung oder
              Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die
              Verarbeitung;
            </li>
            <li>
              dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links
              zu diesen Behörden finden Sie weiter unten);
            </li>
            <li>
              die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben
              haben;
            </li>
            <li>
              ob Profiling durchgeführt wird, ob also Daten automatisch
              ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu
              gelangen.
            </li>
          </ul>
        </li>
        <li>
          Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten,
          was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler
          finden.
        </li>
        <li>
          Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
          Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer
          Daten verlangen dürfen.
        </li>
        <li>
          Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der
          Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern
          dürfen aber nicht weiter verwenden.
        </li>
        <li>
          Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit,
          was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen
          Format zur Verfügung stellen.
        </li>
        <li>
          Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach
          Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
          <ul>
            <li>
              Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e
              (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder
              Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können
              Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach
              so rasch wie möglich, ob wir diesem Widerspruch rechtlich
              nachkommen können.
            </li>
            <li>
              Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie
              jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir
              dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.
            </li>
            <li>
              Werden Daten verwendet, um Profiling zu betreiben, können Sie
              jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir
              dürfen Ihre Daten danach nicht mehr für Profiling verwenden.
            </li>
          </ul>
        </li>
        <li>
          Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer
          ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel
          Profiling) beruhenden Entscheidung unterworfen zu werden.
        </li>
        <li>
          Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt,
          Sie können sich jederzeit bei der Datenschutzbehörde beschweren, wenn
          Sie der Meinung sind, dass die Datenverarbeitung von personenbezogenen
          Daten gegen die DSGVO verstößt.
        </li>
      </ul>
      <p></p>
      <p>
        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
        Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in
        sonst einer Weise verletzt worden sind, können Sie sich bei der
        Aufsichtsbehörde beschweren. Diese ist für Österreich die
        Datenschutzbehörde, deren Website Sie unter{" "}
        <Link
          to="https://www.dsb.gv.at/?tid=312394123"
          target="_blank"
          rel="noopener"
        >
          https://www.dsb.gv.at/
        </Link>{" "}
        finden. In Deutschland gibt es für jedes Bundesland einen
        Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die{" "}
        <Link
          to="https://www.bfdi.bund.de/DE/Home/home_node.html"
          target="_blank"
          rel="noopener"
        >
          Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
          (BfDI)
        </Link>{" "}
        wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde
        zuständig:
      </p>
      <h2 className="text-2xl mt-4" id="bremen-datenschutzbehoerde">
        Die Landesbeauftragte für Datenschutz und Informationsfreiheit der
        Freien Hansestadt Bremen
      </h2>
      <p>
        <strong>Adresse: </strong>Arndtstraße 1, 27570 Bremerhaven
        <br />
        <strong>Telefonnr.:</strong>+49 0421 361 2010
        <br />
        <strong>E-Mail-Adresse:</strong>office@datenschutz.bremen.de
        <br />
        <strong>Website: </strong>{" "}
        <Link
          to="https://www.datenschutz.bremen.de/"
          target="_blank"
          rel="noopener"
        >
          https://www.datenschutz.bremen.de/
        </Link>{" "}
      </p>
      <h2 className="text-2xl mt-4" id="datenuebertragung-drittlaender">
        Datenübertragung in Drittländer
      </h2>
      <p>
        Wir übertragen oder verarbeiten Daten nur dann in Länder außerhalb der
        EU (Drittländer), wenn Sie dieser Verarbeitung zustimmen, dies
        gesetzlich vorgeschrieben ist oder vertraglich notwendig und in jedem
        Fall nur soweit dies generell erlaubt ist. Ihre Zustimmung ist in den
        meisten Fällen der wichtigste Grund, dass wir Daten in Drittländern
        verarbeiten lassen. Die Verarbeitung personenbezogener Daten in
        Drittländern wie den USA, wo viele Softwarehersteller Dienstleistungen
        anbieten und Ihre Serverstandorte haben, kann bedeuten, dass
        personenbezogene Daten auf unerwartete Weise verarbeitet und gespeichert
        werden.
      </p>
      <p>
        Wir weisen ausdrücklich darauf hin, dass nach Meinung des Europäischen
        Gerichtshofs derzeit kein angemessenes Schutzniveau für den
        Datentransfer in die USA besteht. Die Datenverarbeitung durch US-Dienste
        (wie beispielsweise Google Analytics) kann dazu führen, dass
        gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert
        werden. Ferner können gegebenenfalls US-amerikanische staatliche
        Behörden Zugriff auf einzelne Daten nehmen. Zudem kann es vorkommen,
        dass erhobene Daten mit Daten aus anderen Diensten desselben Anbieters,
        sofern Sie ein entsprechendes Nutzerkonto haben, verknüpft werden. Nach
        Möglichkeit versuchen wir Serverstandorte innerhalb der EU zu nutzen,
        sofern das angeboten wird.
      </p>
      <p>
        Wir informieren Sie an den passenden Stellen dieser Datenschutzerklärung
        genauer über Datenübertragung in Drittländer, sofern diese zutrifft.
      </p>
      <h2 className="text-2xl mt-4" id="sicherheit-datenverarbeitung">
        Sicherheit der Datenverarbeitung
      </h2>
      <p>
        Um personenbezogene Daten zu schützen, haben wir sowohl technische als
        auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist,
        verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch
        machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich,
        dass Dritte aus unseren Daten auf persönliche Informationen schließen
        können.
      </p>
      <p>
        Art. 25 DSGVO spricht hier von “Datenschutz durch Technikgestaltung und
        durch datenschutzfreundliche Voreinstellungen” und meint damit, dass man
        sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang
        zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen
        setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete
        Maßnahmen ein.
      </p>
      <h2 className="text-2xl mt-4" id="tls-verschluesselung-https">
        TLS-Verschlüsselung mit https
      </h2>
      <p>
        TLS, Verschlüsselung und https klingen sehr technisch und sind es auch.
        Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für
        „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im
        Internet zu übertragen.
        <br />
        Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem
        Browser zu unserem Webserver abgesichert ist – niemand kann “mithören”.
      </p>
      <p>
        Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und
        erfüllen den Datenschutz durch Technikgestaltung ({" "}
        <Link
          to="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=312394123"
          target="_blank"
          rel="noopener"
        >
          Artikel 25 Absatz 1 DSGVO
        </Link>{" "}
        ). Durch den Einsatz von TLS (Transport Layer Security), einem
        Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet,
        können wir den Schutz vertraulicher Daten sicherstellen.
        <br />
        Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am
        kleinen Schlosssymbol
        <img
          role="img"
          src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg"
          width="17"
          height="18"
        />
        links oben im Browser, links von der Internetadresse (z. B.
        beispielseite.de) und der Verwendung des Schemas https (anstatt http)
        als Teil unserer Internetadresse.
        <br />
        Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir
        die Google Suche nach “Hypertext Transfer Protocol Secure wiki” um gute
        Links zu weiterführenden Informationen zu erhalten.
      </p>
      <h2 className="text-2xl mt-4" id="kommunikation">
        Kommunikation
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>Kommunikation Zusammenfassung</strong> <br />
              👥 Betroffene: Alle, die mit uns z.B. per E-Mail oder
              Online-Formular kommunizieren
              <br />
              📓 Verarbeitete Daten: z. B. Name, E-Mail-Adresse, eingegebene
              Formulardaten. Mehr Details dazu finden Sie bei der jeweils
              eingesetzten Kontaktart
              <br />
              🤝 Zweck: Abwicklung der Kommunikation mit Kunden,
              Geschäftspartnern usw.
              <br />
              📅 Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen
              Vorschriften
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO
              (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder
        Online-Formular kommunizieren, kann es zur Verarbeitung
        personenbezogener Daten kommen.
      </p>
      <p>
        Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des
        damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während
        eben solange gespeichert bzw. solange es das Gesetz vorschreibt.
      </p>
      <h2 className="text-2xl mt-4">Betroffene Personen</h2>
      <p>
        Von den genannten Vorgängen sind alle betroffen, die über die von uns
        bereit gestellten Kommunikationswege den Kontakt zu uns suchen.
      </p>
      <h2 className="text-2xl mt-4">Telefon</h2>
      <p>
        Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät
        und beim eingesetzten Telekommunikationsanbieter pseudonymisiert
        gespeichert. Außerdem können Daten wie Name und Telefonnummer im
        Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert
        werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet
        wurde und es gesetzliche Vorgaben erlauben.
      </p>
      <h2 className="text-2xl mt-4">E-Mail</h2>
      <p>
        Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls
        auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,…) gespeichert
        und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten
        werden gelöscht, sobald der Geschäftsfall beendet wurde und es
        gesetzliche Vorgaben erlauben.
      </p>
      <h2 className="text-2xl mt-4">Online Formulare</h2>
      <p>
        Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf
        unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse
        von uns weitergeleitet. Die Daten werden gelöscht, sobald der
        Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlagen</h2>
      <p>
        Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:
      </p>
      <ul>
        <li>
          Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die
          Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall
          betreffende Zwecke zu verwenden;
        </li>
        <li>
          Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für
          die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter
          wie z. B. dem Telefonanbieter oder wir müssen die Daten für
          vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines
          Angebots, verarbeiten;
        </li>
        <li>
          Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen
          Kundenanfragen und geschäftliche Kommunikation in einem
          professionellen Rahmen betreiben. Dazu sind gewisse technische
          Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und
          Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben
          zu können.
        </li>
      </ul>
      <h2 className="text-2xl mt-4" id="cookies">
        Cookies
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>Cookies Zusammenfassung</strong> <br />
              👥 Betroffene: Besucher der Website
              <br />
              🤝 Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden
              Sie weiter unten bzw. beim Hersteller der Software, der das Cookie
              setzt.
              <br />
              📓 Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie.
              Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der
              Software, der das Cookie setzt.
              <br />
              📅 Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden
              bis hin zu Jahren variieren
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was sind Cookies?</h2>
      <p>
        Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu
        speichern.
        <br />
        Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt
        werden, damit Sie die folgende Datenschutzerklärung besser verstehen.
      </p>
      <p>
        Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser.
        Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet
        Explorer und Microsoft Edge. Die meisten Websites speichern kleine
        Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
      </p>
      <p>
        Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche
        Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen
        sind es HTTP-Cookies, da es auch noch andere Cookies für andere
        Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von
        unserer Website auf Ihrem Computer gespeichert werden. Diese
        Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn”
        Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und
        einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder
        mehrere Attribute angegeben werden.
      </p>
      <p>
        Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise
        Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite
        wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
        Informationen an unsere Seite zurück. Dank der Cookies weiß unsere
        Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt
        sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen
        wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei
        gespeichert.
      </p>
      <p>
        Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem
        Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der
        Webbrowser eine Website an und erhält vom Server ein Cookie zurück,
        welches der Browser erneut verwendet, sobald eine andere Seite
        angefordert wird.
      </p>
      <p>
        <img
          role="img"
          src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg"
          alt="HTTP Cookie Interaktion zwischen Browser und Webserver"
          width="100%"
        />
      </p>
      <p>
        Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies.
        Erstanbieter-Cookies werden direkt von unserer Seite erstellt,
        Drittanbieter-Cookies werden von Partner-Websites (z.B. Google
        Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes
        Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies
        variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind
        keine Software-Programme und enthalten keine Viren, Trojaner oder andere
        „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs
        zugreifen.
      </p>
      <p>So können zum Beispiel Cookie-Daten aussehen:</p>
      <p>
        <strong>Name:</strong> _ga
        <br />
        <strong>Wert:</strong> GA1.2.1326744211.152312394123-9
        <br />
        <strong>Verwendungszweck:</strong> Unterscheidung der Websitebesucher
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
      <ul>
        <li>Mindestens 4096 Bytes pro Cookie</li>
        <li>Mindestens 50 Cookies pro Domain</li>
        <li>Mindestens 3000 Cookies insgesamt</li>
      </ul>
      <h2 className="text-2xl mt-4">Welche Arten von Cookies gibt es?</h2>
      <p>
        Die Frage welche Cookies wir im Speziellen verwenden, hängt von den
        verwendeten Diensten ab und wird in den folgenden Abschnitten der
        Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
        verschiedenen Arten von HTTP-Cookies eingehen.
      </p>
      <p>Man kann 4 Arten von Cookies unterscheiden:</p>
      <p>
        <strong>
          Unerlässliche Cookies
          <br />
        </strong>
        Diese Cookies sind nötig, um grundlegende Funktionen der Website
        sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User
        ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft
        und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb
        nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
      </p>
      <p>
        <strong>
          Zweckmäßige Cookies
          <br />
        </strong>
        Diese Cookies sammeln Infos über das Userverhalten und ob der User
        etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies
        auch die Ladezeit und das Verhalten der Website bei verschiedenen
        Browsern gemessen.
      </p>
      <p>
        <strong>
          Zielorientierte Cookies
          <br />
        </strong>
        Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
        Beispielsweise werden eingegebene Standorte, Schriftgrößen oder
        Formulardaten gespeichert.
      </p>
      <p>
        <strong>
          Werbe-Cookies
          <br />
        </strong>
        Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem
        User individuell angepasste Werbung zu liefern. Das kann sehr praktisch,
        aber auch sehr nervig sein.
      </p>
      <p>
        Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt,
        welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese
        Entscheidung auch in einem Cookie gespeichert.
      </p>
      <p>
        Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen
        nicht scheuen, empfehlen wir{" "}
        <Link to="https://datatracker.ietf.org/doc/html/rfc6265">
          https://datatracker.ietf.org/doc/html/rfc6265
        </Link>{" "}
        , dem Request for Comments der Internet Engineering Task Force (IETF)
        namens “HTTP State Management Mechanism”.
      </p>
      <h2 className="text-2xl mt-4">Zweck der Verarbeitung über Cookies</h2>
      <p>
        Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details
        dazu finden Sie weiter unten bzw. beim Hersteller der Software, die das
        Cookie setzt.
      </p>
      <h2 className="text-2xl mt-4">Welche Daten werden verarbeitet?</h2>
      <p>
        Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben.
        Welche Daten in Cookies gespeichert werden, kann man leider nicht
        verallgemeinern, aber wir werden Sie im Rahmen der folgenden
        Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten
        informieren.
      </p>
      <h2 className="text-2xl mt-4">Speicherdauer von Cookies</h2>
      <p>
        Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter
        präzisiert. Manche Cookies werden nach weniger als einer Stunde
        gelöscht, andere können mehrere Jahre auf einem Computer gespeichert
        bleiben.
      </p>
      <p>
        Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können
        über ihren Browser sämtliche Cookies jederzeit manuell löschen (siehe
        auch unten “Widerspruchsrecht”). Ferner werden Cookies, die auf einer
        Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung
        gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin unberührt
        bleibt.
      </p>
      <h2 className="text-2xl mt-4">
        Widerspruchsrecht – wie kann ich Cookies löschen?
      </h2>
      <p>
        Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst.
        Unabhängig von welchem Service oder welcher Website die Cookies stammen,
        haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder
        nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von
        Drittanbietern blockieren, aber alle anderen Cookies zulassen.
      </p>
      <p>
        Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser
        gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen
        wollen, können Sie dies in Ihren Browser-Einstellungen finden:
      </p>
      <p>
        {" "}
        <Link
          to="https://support.google.com/chrome/answer/95647?tid=312394123"
          target="_blank"
          rel="noopener noreferrer"
        >
          Chrome: Cookies in Chrome löschen, aktivieren und verwalten
        </Link>{" "}
      </p>
      <p>
        {" "}
        <Link
          to="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=312394123"
          target="_blank"
          rel="noopener noreferrer"
        >
          Safari: Verwalten von Cookies und Websitedaten mit Safari
        </Link>{" "}
      </p>
      <p>
        {" "}
        <Link
          to="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=312394123"
          target="_blank"
          rel="noopener noreferrer"
        >
          Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
          Ihrem Computer abgelegt haben
        </Link>{" "}
      </p>
      <p>
        {" "}
        <Link to="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=312394123">
          Internet Explorer: Löschen und Verwalten von Cookies
        </Link>{" "}
      </p>
      <p>
        {" "}
        <Link to="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=312394123">
          Microsoft Edge: Löschen und Verwalten von Cookies
        </Link>{" "}
      </p>
      <p>
        Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
        Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
        gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
        entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise
        ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in
        Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies
        deaktivieren Chrome” im Falle eines Chrome Browsers.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist
        festgehalten, dass das Speichern von Cookies eine
        <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen
        verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr
        unterschiedliche Reaktionen auf diese Richtlinien. In Österreich
        erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des
        Telekommunikationsgesetzes (TKG). In Deutschland wurden die
        Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen
        erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des
        Telemediengesetzes (TMG).
      </p>
      <p>
        Für unbedingt notwendige Cookies, auch soweit keine Einwilligung
        vorliegt, bestehen <strong>berechtigte Interessen</strong> (Artikel 6
        Abs. 1 lit. f DSGVO), die in den meisten Fällen wirtschaftlicher Natur
        sind. Wir möchten den Besuchern der Website eine angenehme
        Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft
        unbedingt notwendig.
      </p>
      <p>
        Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen,
        geschieht dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist
        insoweit Art. 6 Abs. 1 lit. a DSGVO.
      </p>
      <p>
        In den folgenden Abschnitten werden Sie genauer über den Einsatz von
        Cookies informiert, sofern eingesetzte Software Cookies verwendet.
      </p>
      <h2 className="text-2xl mt-4" id="kundendaten">
        Kundendaten
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>Kundendaten Zusammenfassung</strong> <br />
              👥 Betroffene: Kunden bzw. Geschäfts- und Vertragspartner
              <br />
              🤝 Zweck: Erbringung der vertraglich oder vorvertraglich
              vereinbarten Leistungen einschließlich zugehörige Kommunikation
              <br />
              📓 Verarbeitete Daten: Name, Adresse, Kontaktdaten,
              E-Mail-Adresse, Telefonnummer, Zahlungsinformationen (wie z. B.
              Rechnungen und Bankdaten), Vertragsdaten (wie z. B. Laufzeit und
              Gegenstand des Vertrags), IP-Adresse, Bestelldaten
              <br />
              📅 Speicherdauer: die Daten werden gelöscht, sobald sie zur
              Erbringung unserer geschäftlichen Zwecke nicht mehr erforderlich
              sind und es keine gesetzliche Aufbewahrungspflicht gibt.
              <br />
              ⚖️ Rechtsgrundlagen: Berechtigtes Interesse (Art. 6 Abs. 1 lit. f
              DSGVO), Vertrag (Art. 6 Abs 1 lit. b DSGVO)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was sind Kundendaten?</h2>
      <p>
        Damit wir unser Service bzw. unsere vertraglichen Leistungen anbieten
        können, verarbeiten wir auch Daten unserer Kunden und Geschäftspartner.
        Unter diesen Daten befinden sich immer auch personenbezogene Daten.
        Unter Kundendaten versteht man alle Informationen, die auf Basis einer
        vertraglichen oder vorvertraglichen Zusammenarbeit verarbeitet werden,
        um die angebotenen Leistungen erbringen zu können. Kundendaten sind also
        alle gesammelten Informationen, die wir über unsere Kunden erheben und
        verarbeiten.
      </p>
      <h2 className="text-2xl mt-4">Warum verarbeiten wir Kundendaten?</h2>
      <p>
        Es gibt viele Gründe, warum wir Kundendaten sammeln und verarbeiten. Der
        wichtigste ist, dass wir zur Bereitstellung unserer Services einfach
        verschiedene Daten benötigen. Manchmal reicht hier schon Ihre
        E-Mail-Adresse, doch wenn Sie etwa ein Produkt oder eine Dienstleistung
        erwerben, benötigen wir auch Daten wie beispielsweise Name, Adresse,
        Bankdaten oder Vertragsdaten. Wir nutzen die Daten in weiterer Folge
        auch für Marketing- und Vertriebsoptimierungen, damit wir insgesamt
        unser Service für unsere Kunden verbessern können. Ein weiterer
        wichtiger Punkt ist auch unser Kundenservice, der uns stets sehr am
        Herzen liegt. Wir wollen, dass Sie mit Frage zu unseren Angeboten
        jederzeit zu uns kommen können und dafür brauchen wir zumindest Ihre
        E-Mail-Adresse.
      </p>
      <h2 className="text-2xl mt-4">Welche Daten werden verarbeitet?</h2>
      <p>
        Welche Daten genau gespeichert werden, kann an dieser Stelle nur anhand
        Kategorien wiedergegeben werden. Dies hängt nämlich stets davon ab,
        welche Leistungen Sie von uns beziehen. In manchen Fällen geben Sie uns
        lediglich Ihre E-Mail-Adresse, damit wir beispielsweise mit Ihnen in
        Kontakt treten können oder wir Ihre Fragen beantworten können. In
        anderen Fällen erwerben Sie ein Produkt oder eine Dienstleistung bei uns
        und dafür benötigen wir deutlich mehr Informationen, wie etwa Ihre
        Kontaktdaten, Zahlungsdaten und Vertragsdaten.
      </p>
      <p>
        Hier eine Liste möglicher Daten, die wir von Ihnen erhalten und
        verarbeiten:
      </p>
      <ul>
        <li>Name</li>
        <li>Kontaktadresse</li>
        <li>E-Mail-Adresse</li>
        <li>Telefonnummer</li>
        <li>Geburtsdatum</li>
        <li>Gesundheitsdaten im Rahmen von Antragsstellungen</li>
        <li>Zahlungsdaten (Rechnungen, Bankdaten, Zahlungshistorie etc.)</li>
        <li>Vertragsdaten ( Laufzeit, Inhalt)</li>
        <li>Nutzungsdaten (besuchte Websites, Zugriffsdaten ect.)</li>
        <li>Metadaten (IP-Adresse, Geräte-Informationen)</li>
      </ul>
      <h2 className="text-2xl mt-4">Wie lange werden die Daten gespeichert?</h2>
      <p>
        Sobald wir die Kundendaten zur Erfüllung unserer vertraglichen Pflichten
        und unserer Zwecke nicht mehr benötigen und die Daten auch nicht für
        mögliche Gewährleistungs- und Haftungspflichten nötig sind, löschen wir
        die entsprechenden Kundendaten. Das ist zum Beispiel der Fall, wenn ein
        geschäftlicher Vertrag endet. Danach beträgt die Verjährungsfrist in der
        Regel 3 Jahre, wobei längere Fristen im Einzelfall möglich sind. Wir
        halten uns natürlich auch an die gesetzlichen Aufbewahrungspflichten.
        Ihre Kundendaten werden ganz sicher nicht an Dritte weitergegeben, wenn
        Sie dazu nicht explizit eine Einwilligung erteilt haben.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Rechtsgrundlagen für die Verarbeitung Ihrer Daten sind Art. 6 Abs. 1
        lit. a DSGVO (Einwilligung), Art. 6 Abs 1 lit. b DSGVO (Vertrag bzw.
        vorvertragliche Maßnahmen), Art. 6 Abs. 1 lit. f DSGVO (berechtigte
        Interessen) und in speziellen Fällen (z. B. bei medizinischen
        Leistungen) Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung besonderer
        Kategorien).
      </p>
      <p>
        Für den Fall des Schutzes lebenswichtiger Interessen erfolgt die
        Datenverarbeitung gemäß Art. 9 Abs. 2 lit. c. DSGVO. Für Zwecke der
        Gesundheitsversorgung, der Arbeitsmedizin, für die medizinische
        Diagnostik, für die Versorgung oder Behandlung im Gesundheits- oder
        Sozialbereich oder für die Verwaltung von Systemen und Diensten im
        Gesundheits- oder Sozialbereich erfolgt die Verarbeitung
        personenbezogener Daten gemäß Art. 9 Abs. 2 lit. h. DSGVO. Wenn Sie
        freiwillig Daten der besonderen Kategorien mitteilen, erfolgt die
        Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.
      </p>
      <h2 className="text-2xl mt-4" id="webhosting-einleitung">
        Webhosting Einleitung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>Webhosting Zusammenfassung</strong> <br />
              👥 Betroffene: Besucher der Website
              <br />
              🤝 Zweck: professionelles Hosting der Website und Absicherung des
              Betriebs
              <br />
              📓 Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs,
              verwendeter Browser und weitere Daten. Mehr Details dazu finden
              Sie weiter unten bzw. beim jeweils eingesetzten Webhosting
              Provider.
              <br />
              📅 Speicherdauer: abhängig vom jeweiligen Provider, aber in der
              Regel 2 Wochen
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte
              Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was ist Webhosting?</h2>
      <p>
        Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen –
        auch personenbezogene Daten – automatisch erstellt und gespeichert, so
        auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur
        mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die
        Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der
        Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser
        hier). Mit Domain meinen wir zum Beispiel beispiel.de oder
        musterbeispiel.com.
      </p>
      <p>
        Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen
        möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt.
        Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome,
        Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser
        oder Webbrowser dazu.
      </p>
      <p>
        Um die Website aufzurufen, muss sich der Browser zu einem anderen
        Computer verbinden, wo der Code der Website gespeichert ist: dem
        Webserver. Der Betrieb eines Webservers ist eine komplizierte und
        aufwendige Aufgabe, weswegen dies in der Regel von professionellen
        Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an
        und sorgen damit für eine verlässliche und fehlerfreie Speicherung der
        Daten von Websites. Eine ganze Menge Fachbegriffe, aber bitte bleiben
        Sie dran, es wird noch besser!
      </p>
      <p>
        Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop,
        Laptop, Tablet oder Smartphone) und während der Datenübertragung zu und
        vom Webserver kann es zu einer Verarbeitung personenbezogener Daten
        kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch
        der Webserver Daten eine Zeit lang speichern, um einen ordentlichen
        Betrieb zu gewährleisten.
      </p>
      <p>
        Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur
        Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und
        dem Hosting-Provider.
      </p>
      <p>
        <img
          role="img"
          src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg"
          alt="Browser und Webserver"
          width="100%"
        />
      </p>
      <h2 className="text-2xl mt-4">
        Warum verarbeiten wir personenbezogene Daten?
      </h2>
      <p>Die Zwecke der Datenverarbeitung sind:</p>
      <ol>
        <li>
          Professionelles Hosting der Website und Absicherung des Betriebs
        </li>
        <li>zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
        <li>
          Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres
          Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen
        </li>
      </ol>
      <h2 className="text-2xl mt-4">Welche Daten werden verarbeitet?</h2>
      <p>
        Auch während Sie unsere Website jetzt gerade besuchen, speichert unser
        Webserver, das ist der Computer auf dem diese Webseite gespeichert ist,
        in der Regel automatisch Daten wie
      </p>
      <ul>
        <li>die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
        <li>Browser und Browserversion (z. B. Chrome 87)</li>
        <li>das verwendete Betriebssystem (z. B. Windows 10)</li>
        <li>
          die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.{" "}
          <Link
            to="https://www.beispielquellsite.de/vondabinichgekommen/"
            target="_blank"
            rel="follow noopener"
          >
            https://www.beispielquellsite.de/vondabinichgekommen/
          </Link>{" "}
          )
        </li>
        <li>
          den Hostnamen und die IP-Adresse des Geräts von welchem aus
          zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)
        </li>
        <li>Datum und Uhrzeit</li>
        <li>in Dateien, den sogenannten Webserver-Logfiles</li>
      </ul>
      <h2 className="text-2xl mt-4">Wie lange werden Daten gespeichert?</h2>
      <p>
        In der Regel werden die oben genannten Daten zwei Wochen gespeichert und
        danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können
        jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
        rechtswidrigem Verhalten von Behörden eingesehen werden.
      </p>
      <p>
        <strong>Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider
        (Firma, die unsere Website auf speziellen Computern (Servern) laufen
        lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung
        weiter!
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen
        des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der
        berechtigten Interessen), denn die Nutzung von professionellem Hosting
        bei einem Provider ist notwendig, um das Unternehmen im Internet sicher
        und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus
        gegebenenfalls verfolgen zu können.
      </p>
      <p>
        Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag
        über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung
        von Datenschutz gewährleistet und Datensicherheit garantiert.
      </p>
      <h2 className="text-2xl mt-4">Automatisierungs-Software</h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>Nutzung von Automatisierungs-Software</strong>
              <br />
              👥 Betroffene: Besucher, die einen Antrag absenden
              <br />
              🤝 Zweck: Automatisierte verarbeitung der Antragsdaten zu
              Erfüllung des Auftrags der Antragserstellung und Anlegen der
              Kontakte für spätere Kommunikation.
              <br />
              📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
              Standorte der Zugriffe, Gerätedaten, Personenbezogene Daten, die
              im Rahmen des Antrags angegeben wurden.
              <br />
              📅 Speicherdauer: abhängig vom Antragsprozess
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">
        Automatisierung mit Make.com (Celonis, Inc.)
      </h2>
      <p>
        Make.com ist ein Softwareanbieter für Automatisierungen. Wir haben mit
        Make.com einen Auftragsdaten­verarbeitungs­vertrag (Data processing
        agreement) abgeschlossen. In diesem Vertrag verpflichtet sich Make.com
        dazu, die Daten unserer Nutzer gemäß DSGVO und deren
        Datenschutzbestimmungen zu verarbeiten und zu schützen.
      </p>
      <h2 className="text-2xl mt-4" id="web-analytics-einleitung">
        Web Analytics Einleitung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>
                Web Analytics Datenschutzerklärung Zusammenfassung
              </strong>
              <br />
              👥 Betroffene: Besucher der Website
              <br />
              🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des
              Webangebots.
              <br />
              📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
              Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt,
              Navigationsverhalten, Klickverhalten und IP-Adressen enthalten.
              Mehr Details dazu finden Sie beim jeweils eingesetzten Web
              Analytics Tool.
              <br />
              📅 Speicherdauer: abhängig vom eingesetzten Web-Analytics-Tool
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was ist Web Analytics?</h2>
      <p>
        Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens
        der Website-Besucher, kurz Web Analytics oder Web-Analyse genannt. Dabei
        werden Daten gesammelt, die der jeweilige Analytic-Tool-Anbieter (auch
        Trackingtool genannt) speichert, verwaltet und verarbeitet. Mit Hilfe
        der Daten werden Analysen über das Nutzerverhalten auf unserer Website
        erstellt und uns als Websitebetreiber zur Verfügung gestellt. Zusätzlich
        bieten die meisten Tools verschiedene Testmöglichkeiten an. So können
        wir etwa testen, welche Angebote oder Inhalte bei unseren Besuchern am
        besten ankommen. Dafür zeigen wir Ihnen für einen begrenzten
        Zeitabschnitt zwei verschiedene Angebote. Nach dem Test (sogenannter
        A/B-Test) wissen wir, welches Produkt bzw. welcher Inhalt unsere
        Websitebesucher interessanter finden. Für solche Testverfahren, wie auch
        für andere Analytics-Verfahren, können auch Userprofile erstellt werden
        und die Daten in Cookies gespeichert werden.
      </p>
      <h2 className="text-2xl mt-4">Warum betreiben wir Web Analytics?</h2>
      <p>
        Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen für
        unsere Branche das beste Webangebot auf dem Markt liefern. Um dieses
        Ziel zu erreichen, wollen wir einerseits das beste und interessanteste
        Angebot bieten und andererseits darauf achten, dass Sie sich auf unserer
        Website rundum wohlfühlen. Mit Hilfe von Webanalyse-Tools können wir das
        Verhalten unserer Websitebesucher genauer unter die Lupe nehmen und dann
        entsprechend unser Webangebot für Sie und uns verbessern. So können wir
        beispielsweise erkennen wie alt unsere Besucher durchschnittlich sind,
        woher sie kommen, wann unsere Website am meisten besucht wird oder
        welche Inhalte oder Produkte besonders beliebt sind. All diese
        Informationen helfen uns die Website zu optimieren und entsprechend
        Ihrer Bedürfnisse, Interessen und Wünsche zu überarbeiten.
      </p>
      <h2 className="text-2xl mt-4">Welche Daten werden verarbeitet?</h2>
      <p>
        Welche Daten genau gespeichert werden, hängt natürlich von den
        verwendeten Analyse-Tools ab. Doch in der Regel wird zum Beispiel
        gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf welche
        Buttons oder Links Sie klicken, wann Sie eine Seite aufrufen, welchen
        Browser sie verwenden, mit welchem Gerät (PC, Tablet, Smartphone usw.)
        Sie die Website besuchen oder welches Computersystem Sie verwenden. Wenn
        Sie damit einverstanden waren, dass auch Standortdaten erhoben werden
        dürfen, können auch diese durch den Webanalyse-Tool-Anbieter verarbeitet
        werden.
      </p>
      <p>
        Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der
        Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen personenbezogene
        Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert
        (also in unkenntlicher und gekürzter Form) gespeichert. Für den Zweck
        der Tests, der Webanalyse und der Weboptimierung werden grundsätzlich
        keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder
        Ihre E-Mail-Adresse gespeichert. All diese Daten werden, sofern sie
        erhoben werden, pseudonymisiert gespeichert. So können Sie als Person
        nicht identifiziert werden.
      </p>
      <p>
        Das folgende Beispiel zeigt schematisch die Funktionsweise von Google
        Analytics als Beispiel für client-basiertes Webtracking mit
        Java-Script-Code.
      </p>
      <p>
        <img
          role="img"
          src="https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg"
          alt="Schematischer Datenfluss bei Google Analytics"
          width="100%"
        />
      </p>
      <p>
        Wie lange die jeweiligen Daten gespeichert werden, hängt immer vom
        Anbieter ab. Manche Cookies speichern Daten nur für ein paar Minuten
        bzw. bis Sie die Website wieder verlassen, andere Cookies können Daten
        über mehrere Jahre speichern.
      </p>
      <h2 className="text-2xl mt-4">Dauer der Datenverarbeitung</h2>
      <p>
        Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten,
        sofern wir weitere Informationen dazu haben. Generell verarbeiten wir
        personenbezogene Daten nur so lange wie es für die Bereitstellung
        unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es,
        wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist,
        kann diese Speicherdauer auch überschritten werden.
      </p>
      <h2 className="text-2xl mt-4">Widerspruchsrecht</h2>
      <p>
        Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung
        zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das
        funktioniert entweder über unser Cookie-Management-Tool oder über andere
        Opt-Out-Funktionen. Zum Beispiel können Sie auch die Datenerfassung
        durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies
        verwalten, deaktivieren oder löschen.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche wir
        mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut
        <strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong>
        die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
        sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.
      </p>
      <p>
        Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes
        Interesse daran, das Verhalten der Websitebesucher zu analysieren und so
        unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von
        Web-Analytics erkennen wir Fehler der Website, können Attacken
        identifizieren und die Wirtschaftlichkeit verbessern. Die
        Rechtsgrundlage dafür ist
        <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>.
        Wir setzen die Tools gleichwohl nur ein, soweit sie eine Einwilligung
        erteilt haben.
      </p>
      <p>
        Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir
        Ihnen auch das Lesen unserer allgemeinen Datenschutzerklärung zu
        Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und
        verarbeitet werden, sollten Sie die Datenschutzerklärungen der
        jeweiligen Tools durchlesen.
      </p>
      <p>
        Informationen zu speziellen Web-Analytics-Tools, erhalten Sie – sofern
        vorhanden – in den folgenden Abschnitten.
      </p>
      <h2 className="text-2xl mt-4" id="google-analytics-datenschutzerklaerung">
        Google Analytics Datenschutzerklärung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>
                Google Analytics Datenschutzerklärung Zusammenfassung
              </strong>
              <br />
              👥 Betroffene: Besucher der Website
              <br />
              🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des
              Webangebots.
              <br />
              📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
              Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt,
              Navigationsverhalten, Klickverhalten und IP-Adressen enthalten.
              Mehr Details dazu finden Sie weiter unten in dieser
              Datenschutzerklärung.
              <br />
              📅 Speicherdauer: abhängig von den verwendeten Properties
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was ist Google Analytics?</h2>
      <p>
        Wir verwenden auf unserer Website das Analyse-Tracking Tool Google
        Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den
        europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon
        House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
        verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf
        unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird
        diese Aktion in einem Cookie gespeichert und an Google Analytics
        versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten,
        können wir unsere Website und unser Service besser an Ihre Wünsche
        anpassen. Im Folgenden gehen wir näher auf das Tracking-Tool ein und
        informieren Sie vor allem darüber, welche Daten gespeichert werden und
        wie Sie das verhindern können.
      </p>
      <p>
        Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse
        unserer Website dient. Damit Google Analytics funktioniert, wird ein
        Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere
        Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die
        Sie auf unserer Website ausführen. Sobald Sie unsere Website verlassen,
        werden diese Daten an die Google-Analytics-Server gesendet und dort
        gespeichert.
      </p>
      <p>
        Google verarbeitet die Daten und wir bekommen Berichte über Ihr
        Userverhalten. Dabei kann es sich unter anderem um folgende Berichte
        handeln:
      </p>
      <ul>
        <li>
          Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User
          besser kennen und wissen genauer, wer sich für unser Service
          interessiert.
        </li>
        <li>
          Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung
          leichter analysieren und verbessern.
        </li>
        <li>
          Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche
          Informationen darüber, wie wir mehr Menschen für unser Service
          begeistern können.
        </li>
        <li>
          Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website
          interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer
          Seite zurücklegen und welche Links Sie anklicken.
        </li>
        <li>
          Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie
          aufgrund einer Marketing-Botschaft eine gewünschte Handlung ausführen.
          Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem
          Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte
          erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen
          ankommen. So wollen wir unsere Conversionrate steigern.
        </li>
        <li>
          Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf
          unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade
          diesen Text lesen.
        </li>
      </ul>
      <h2 className="text-2xl mt-4">
        Warum verwenden wir Google Analytics auf unserer Website?
      </h2>
      <p>
        Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das
        bestmögliche Service bieten. Die Statistiken und Daten von Google
        Analytics helfen uns dieses Ziel zu erreichen.
      </p>
      <p>
        Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den
        Stärken und Schwächen unserer Website. Einerseits können wir unsere
        Seite so optimieren, dass sie von interessierten Menschen auf Google
        leichter gefunden wird. Andererseits helfen uns die Daten, Sie als
        Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an
        unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu
        bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen
        individueller und kostengünstiger durchzuführen. Schließlich macht es
        nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die
        sich dafür interessieren.
      </p>
      <h2 className="text-2xl mt-4">
        Welche Daten werden von Google Analytics gespeichert?
      </h2>
      <p>
        Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige,
        eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt
        Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere
        Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle
        gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So
        ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.
      </p>
      <p>
        Um mit Google Analytics unsere Website analysieren zu können, muss eine
        Property-ID in den Tracking-Code eingefügt werden. Die Daten werden dann
        in der entsprechenden Property gespeichert. Für jede neu angelegte
        Property ist die Google Analytics 4-Property standardmäßig. Alternativ
        kann man aber auch noch die Universal Analytics Property erstellen. Je
        nach verwendeter Property werden Daten unterschiedlich lange
        gespeichert.
      </p>
      <p>
        Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre
        Interaktionen auf unserer Website gemessen. Interaktionen sind alle
        Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie
        auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können
        über Google Analytics generierte Daten mit Drittanbieter-Cookies
        verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer
        wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen,
        wenn es gesetzlich erforderlich ist.
      </p>
      <p>Folgende Cookies werden von Google Analytics verwendet:</p>
      <p>
        <strong>Name:</strong> _ga
        <br />
        <strong>Wert: </strong>2.1326744211.152312394123-5
        <br />
        <strong>Verwendungszweck:</strong> Standardmäßig verwendet analytics.js
        das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur
        Unterscheidung der Webseitenbesucher.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> _gid
        <br />
        <strong>Wert: </strong>2.1687193234.152312394123-1
        <br />
        <strong>Verwendungszweck:</strong> Das Cookie dient auch zur
        Unterscheidung der Webseitenbesucher
        <br />
        <strong>Ablaufdatum:</strong> nach 24 Stunden
      </p>
      <p>
        <strong>Name:</strong> _gat_gtag_UA_&lt;property-id
        <br />
        <strong>Wert:</strong> 1<br />
        <strong>Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate
        verwendet. Wenn Google Analytics über den Google Tag Manager
        bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_
        &lt;property-id.
        <br />
        <strong>Ablaufdatum: </strong>nach 1 Minute
      </p>
      <p>
        <strong>Name:</strong> AMP_TOKEN
        <br />
        <strong>Wert:</strong> keine Angaben
        <br />
        <strong>Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem
        eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann. Andere
        mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen Fehler
        hin.
        <br />
        <strong>Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr
      </p>
      <p>
        <strong>Name:</strong> __utma
        <br />
        <strong>Wert: </strong>1564498958.1564498958.1564498958.1
        <br />
        <strong>Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr
        Verhalten auf der Website verfolgen und die Leistung messen. Das Cookie
        wird jedes Mal aktualisiert, wenn Informationen an Google Analytics
        gesendet werden.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> __utmt
        <br />
        <strong>Wert:</strong> 1<br />
        <strong>Verwendungszweck:</strong> Das Cookie wird wie
        _gat_gtag_UA_&lt;property-id zum Drosseln der Anforderungsrate
        verwendet.
        <br />
        <strong>Ablaufdatum:</strong> nach 10 Minuten
      </p>
      <p>
        <strong>Name:</strong> __utmb
        <br />
        <strong>Wert: </strong>3.10.1564498958
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue
        Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten
        bzw. Infos an Google Analytics gesendet werden.
        <br />
        <strong>Ablaufdatum:</strong> nach 30 Minuten
      </p>
      <p>
        <strong>Name:</strong> __utmc
        <br />
        <strong>Wert:</strong> 167421564
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue
        Sitzungen für wiederkehrende Besucher festzulegen. Dabei handelt es sich
        um ein Session-Cookie und wird nur solange gespeichert, bis Sie den
        Browser wieder schließen.
        <br />
        <strong>Ablaufdatum:</strong> Nach Schließung des Browsers
      </p>
      <p>
        <strong>Name:</strong> __utmz
        <br />
        <strong>Wert:</strong>
        m|utmccn=(referral)|utmcmd=referral|utmcct=/
        <br />
        <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um die
        Quelle des Besucheraufkommens auf unserer Website zu identifizieren. Das
        heißt, das Cookie speichert, von wo Sie auf unsere Website gekommen
        sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.
        <br />
        <strong>Ablaufdatum:</strong> nach 6 Monaten
      </p>
      <p>
        <strong>Name:</strong> __utmv
        <br />
        <strong>Wert:</strong> keine Angabe
        <br />
        <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um
        benutzerdefinierte Userdaten zu speichern. Es wird immer aktualisiert,
        wenn Informationen an Google Analytics gesendet werden.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf
        Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder
        auch verändert.
      </p>
      <p>
        Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die
        mit Google Analytics erhoben werden:
      </p>
      <p>
        <strong>Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über
        Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen
        wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.
      </p>
      <p>
        <strong>Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die
        Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen.
        Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.
      </p>
      <p>
        <strong>Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist
        die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann
        unsere Website wieder verlassen.
      </p>
      <p>
        <strong>Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto
        erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese
        Daten.
      </p>
      <p>
        <strong>IP-Adresse:</strong> Die IP-Adresse wird nur in gekürzter Form
        dargestellt, damit keine eindeutige Zuordnung möglich ist.
      </p>
      <p>
        <strong>Standort:</strong> Über die IP-Adresse kann das Land und Ihr
        ungefährer Standort bestimmt werden. Diesen Vorgang bezeichnet man auch
        als IP- Standortbestimmung.
      </p>
      <p>
        <strong>Technische Informationen:</strong> Zu den technischen
        Informationen zählen unter anderem Ihr Browsertyp, Ihr Internetanbieter
        oder Ihre Bildschirmauflösung.
      </p>
      <p>
        <strong>Herkunftsquelle:</strong> Google Analytics beziehungsweise uns
        interessiert natürlich auch über welche Website oder welche Werbung Sie
        auf unsere Seite gekommen sind.
      </p>
      <p>
        Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von
        Medien (z. B. wenn Sie ein Video über unsere Seite abspielen), das
        Teilen von Inhalten über Social Media oder das Zufügen zu Ihren
        Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und dient
        nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google
        Analytics.
      </p>
      <h2 className="text-2xl mt-4">
        Wie lange und wo werden die Daten gespeichert?
      </h2>
      <p>
        Google hat ihre Server auf der ganzen Welt verteilt. Die meisten Server
        befinden sich in Amerika und folglich werden Ihre Daten meist auf
        amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo
        sich die Google-Rechenzentren befinden:{" "}
        <Link
          to="https://www.google.com/about/datacenters/locations/?hl=de"
          target="_blank"
          rel="follow noopener"
        >
          https://www.google.com/about/datacenters/locations/?hl=de
        </Link>{" "}
      </p>
      <p>
        Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt.
        Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor
        Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt
        es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise
        die Hardware bei Google ausfällt oder Naturkatastrophen Server
        lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google
        dennoch gering.
      </p>
      <p>
        Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties
        ab. Bei der Verwendung der neueren Google Analytics 4-Properties ist die
        Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt. Für
        andere sogenannte Ereignisdaten haben wir die Möglichkeit eine
        Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu wählen.
      </p>
      <p>
        Bei Universal Analytics-Properties ist bei Google Analytics eine
        Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten standardisiert
        eingestellt. Dann werden Ihre Userdaten gelöscht. Allerdings haben wir
        die Möglichkeit, die Aufbewahrungsdauer von Nutzdaten selbst zu wählen.
        Dafür stehen uns fünf Varianten zur Verfügung:
      </p>
      <ul>
        <li>Löschung nach 14 Monaten</li>
        <li>Löschung nach 26 Monaten</li>
        <li>Löschung nach 38 Monaten</li>
        <li>Löschung nach 50 Monaten</li>
        <li>Keine automatische Löschung</li>
      </ul>
      <p>
        Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht
        werden, wenn Sie innerhalb des von uns gewählten Zeitraums nicht mehr
        unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer
        jedes Mal zurückgesetzt, wenn Sie unsere Website innerhalb des
        festgelegten Zeitraums wieder besuchen.
      </p>
      <p>
        Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die
        Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit
        Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der
        DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf
        aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert.
        Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer
        größeren Einheit.
      </p>
      <h2 className="text-2xl mt-4">
        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
      </h2>
      <p>
        Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht,
        Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen
        oder zu limitieren. Mithilfe des Browser-Add-ons zur Deaktivierung von
        Google Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie,
        dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on können
        Sie unter{" "}
        <Link
          to="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout?hl=de
        </Link>{" "}
        runterladen und installieren. Beachten Sie bitte, dass durch dieses
        Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.
      </p>
      <p>
        Falls Sie grundsätzlich Cookies deaktivieren, löschen oder verwalten
        wollen, finden Sie unter dem Abschnitt „Cookies“ die entsprechenden
        Links zu den jeweiligen Anleitungen der bekanntesten Browser.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche
        wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt
        laut
        <strong>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong>
        die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
        sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.
      </p>
      <p>
        Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes
        Interesse daran, dass Verhalten der Websitebesucher zu analysieren und
        so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe
        von Google Analytics erkennen wir Fehler der Website, können Attacken
        identifizieren und die Wirtschaftlichkeit verbessern. Die
        Rechtsgrundlage dafür ist
        <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>.
        Wir setzen Google Analytics gleichwohl nur ein, soweit Sie eine
        Einwilligung erteilt haben.
      </p>
      <p>
        Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen
        darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein
        angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies
        kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der
        Datenverarbeitung einhergehen.
      </p>
      <p>
        Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
        Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
        Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
        dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46.
        Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual
        Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen
        und sollen sicherstellen, dass Ihre Daten auch dann den europäischen
        Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
        beispielsweise in die USA) überliefert und dort gespeichert werden.
        Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung
        Ihrer relevanten Daten, das europäische Datenschutzniveau zu wahren,
        selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet
        werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der
        EU-Kommission. Sie finden den Beschluss und die entsprechenden
        Standardvertragsklauseln u.a. hier:{" "}
        <Link
          to="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
          target="_blank"
          rel="noopener"
        >
          https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
        </Link>{" "}
      </p>
      <p>
        Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing
        Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie
        unter{" "}
        <Link
          to="https://business.safety.google/intl/de/adsprocessorterms/"
          target="_blank"
          rel="follow noopener"
        >
          https://business.safety.google/intl/de/adsprocessorterms/
        </Link>{" "}
        .
      </p>
      <p>
        Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die
        Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über
        den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links:{" "}
        <Link to="https://marketingplatform.google.com/about/analytics/terms/de/">
          https://marketingplatform.google.com/about/analytics/terms/de/
        </Link>{" "}
        und{" "}
        <Link
          to="https://support.google.com/analytics/answer/6004245?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.google.com/analytics/answer/6004245?hl=de
        </Link>{" "}
        .
      </p>
      <h2
        className="text-2xl mt-4"
        id="google-analytics-berichte-zu-demografischen-merkmalen-und-interessen"
      >
        Google Analytics Berichte zu demografischen Merkmalen und Interessen
      </h2>
      <p>
        Wir haben in Google Analytics die Funktionen für Werbeberichte
        eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen
        enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können wir
        uns – ohne diese Daten einzelnen Personen zuordnen zu können – ein
        besseres Bild von unseren Nutzern machen. Mehr über die Werbefunktionen
        erfahren Sie auf{" "}
        <Link to="https://support.google.com/analytics/answer/3450482?hl=de">
          https://support.google.com/analytics/answer/3450482?hl=de
        </Link>{" "}
        .
      </p>
      <p>
        Sie können die Nutzung der Aktivitäten und Informationen Ihres Google
        Kontos unter “Einstellungen für Werbung” auf{" "}
        <Link to="https://adssettings.google.com/authenticated">
          https://adssettings.google.com/authenticated
        </Link>{" "}
        per Checkbox beenden.
      </p>
      <h2 className="text-2xl mt-4" id="google-analytics-im-einwilligungsmodus">
        Google Analytics im Einwilligungsmodus
      </h2>
      <p>
        Abhängig von Ihrer Einwilligung werden im sogenannten Einwilligungsmodus
        (bzw. „Consent Mode“) personenbezogene Daten von Ihnen durch Google
        Analytics verarbeitet. Sie können wählen, ob Sie
        Google-Analytics-Cookies zustimmen oder nicht. Damit wählen Sie auch,
        welche Daten Google Analytics von Ihnen verarbeitet darf. Diese
        erhobenen Daten werden hauptsächlich dafür verwendet, Messungen über das
        Userverhalten auf der Website durchzuführen, zielgerichtete Werbung
        auszuspielen und uns Web-Analyseberichte zu liefern. In der Regel
        willigen Sie der Datenverarbeitung durch Google über ein
        Cookie-Consent-Tool ein. Wenn Sie der Datenverarbeitung nicht
        einwilligen, werden nur aggregierte Daten erfasst und verarbeitet. Das
        bedeutet, Daten können einzelnen Usern nicht zugeordnet werden und es
        entsteht somit kein Userprofil von Ihnen. Sie können auch nur der
        statistischen Messung zustimmen. Dabei werden keine personenbezogenen
        Daten verarbeitet und folglich nicht für Werbungen oder Werbemesserfolge
        verwendet.
      </p>
      <h2 className="text-2xl mt-4">Tracking mit Hotjar</h2>
      <p>
        Wir nutzen Hotjar, um die Bedürfnisse unserer Nutzer besser zu verstehen
        und das Angebot und die Erfahrung auf dieser Webseite zu optimieren.
        Mithilfe der Technologie von Hotjar bekommen wir ein besseres
        Verständnis von den Erfahrungen unserer Nutzer (z.B. wieviel Zeit Nutzer
        auf welchen Seiten verbringen, welche Links sie anklicken, was sie mögen
        und was nicht etc.) und das hilft uns, unser Angebot am Feedback unserer
        Nutzer auszurichten. Hotjar arbeitet mit Cookies und anderen
        Technologien, um Daten über das Verhalten unserer Nutzer und über ihre
        Endgeräte zu erheben, insbesondere IP Adresse des Geräts (wird während
        Ihrer Website-Nutzung nur in anonymisierter Form erfasst und
        gespeichert), Bildschirmgröße, Gerätetyp (Unique Device Identifiers),
        Informationen über den verwendeten Browser, Standort (nur Land), zum
        Anzeigen unserer Webseite bevorzugte Sprache. Hotjar speichert diese
        Informationen in unserem Auftrag in einem pseudonymisierten
        Nutzerprofil. Hotjar ist es vertraglich verboten, die in unserem Auftrag
        erhobenen Daten zu verkaufen.
      </p>
      <p>
        Weitere Informationen finden Sie in unter dem Abschnitt ‚about Hotjar‘
        auf{" "}
        <Link
          to="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Hotjars Hilfe-Seite
        </Link>{" "}
        .
      </p>
      <p></p>
      <h2 className="text-2xl mt-4" id="e-mail-marketing-einleitung">
        E-Mail-Marketing Einleitung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>E-Mail-Marketing Zusammenfassung</strong> <br />
              👥 Betroffene: Newsletter-Abonnenten
              <br />
              🤝 Zweck: Direktwerbung per E-Mail, Benachrichtigung über
              systemrelevante Ereignisse
              <br />
              📓 Verarbeitete Daten: Eingegebene Daten bei der Registrierung
              jedoch mindestens die E-Mail-Adresse. Mehr Details dazu finden Sie
              beim jeweils eingesetzten E-Mail-Marketing-Tool.
              <br />
              📅 Speicherdauer: Dauer des Bestehens des Abonnements
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was ist E-Mail-Marketing?</h2>
      <p>
        Um Sie stets auf dem Laufenden zu halten, nutzen wir auch die
        Möglichkeit des E-Mail-Marketings. Dabei werden, sofern Sie dem Empfang
        unserer E-Mails bzw. Newsletter zugestimmt haben, auch Daten von Ihnen
        verarbeitet und gespeichert. E-Mail-Marketing ist ein Teilbereich des
        Online-Marketings. Dabei werden Neuigkeiten oder allgemeine
        Informationen über ein Unternehmen, Produkte oder Dienstleistungen per
        E-Mail an eine bestimmte Gruppe an Menschen, die sich dafür
        interessieren, gesendet.
      </p>
      <p>
        Wenn Sie an unserem E-Mail-Marketing (meist per Newsletter) teilnehmen
        wollen, müssen Sie sich im Normalfall einfach nur mit Ihrer
        E-Mail-Adresse anmelden. Dafür füllen Sie ein Online-Formular aus und
        senden es ab. Es kann aber auch vorkommen, dass wir Sie etwa um Ihre
        Anrede und Ihren Namen bitten, damit wir Sie auch persönlich anschreiben
        können.
      </p>
      <p>
        Grundsätzlich funktioniert das Anmelden zu Newslettern mit Hilfe des
        sogenannten „Double-Opt-In-Verfahrens“. Nachdem Sie sich für unseren
        Newsletter auf unserer Website angemeldet haben, bekommen Sie eine
        E-Mail, über die Sie die Newsletter-Anmeldung bestätigen. So wird
        sichergestellt, dass Ihnen die E-Mail-Adresse gehört und sich niemand
        mit einer fremden E-Mail-Adresse angemeldet hat. Wir oder ein von uns
        verwendetes Benachrichtigungs-Tool protokolliert jede einzelne
        Anmeldung. Dies ist nötig, damit wir den rechtlich korrekten
        Anmeldevorgang auch nachweisen können. Dabei wird in der Regel der
        Zeitpunkt der Anmeldung, der Zeitpunkt der Anmeldebestätigung und Ihre
        IP-Adresse gespeichert. Zusätzlich wird auch protokolliert, wenn Sie
        Änderungen Ihrer gespeicherten Daten vornehmen.
      </p>
      <h2 className="text-2xl mt-4">Warum nutzen wir E-Mail-Marketing?</h2>
      <p>
        Wir wollen natürlich mit Ihnen in Kontakt bleiben und Ihnen stets die
        wichtigsten Neuigkeiten über unser Unternehmen präsentieren. Dafür
        nutzen wir unter anderem E-Mail-Marketing – oft auch nur “Newsletter”
        bezeichnet – als wesentlichen Bestandteil unseres Online-Marketings.
        Sofern Sie sich damit einverstanden erklären oder es gesetzlich erlaubt
        ist, schicken wir Ihnen Newsletter, System-E-Mails oder andere
        Benachrichtigungen per E-Mail. Wenn wir im folgenden Text den Begriff
        „Newsletter“ verwenden, meinen wir damit hauptsächlich regelmäßig
        versandte E-Mails. Natürlich wollen wir Sie mit unseren Newsletter in
        keiner Weise belästigen. Darum sind wir wirklich stets bemüht, nur
        relevante und interessante Inhalte zu bieten. So erfahren Sie etwa mehr
        über unser Unternehmen, unsere Leistungen oder Produkte. Da wir unsere
        Angebote auch immer verbessern, erfahren Sie über unseren Newsletter
        auch immer, wenn es Neuigkeiten gibt oder wir gerade spezielle,
        lukrative Aktionen anbieten. Sofern wir einen Dienstleister, der ein
        professionelles Versand-Tool anbietet, für unser E-Mail-Marketing
        beauftragen, machen wir das, um Ihnen schnelle und sichere Newsletter
        bieten zu können. Zweck unseres E-Mail-Marketings ist grundsätzlich, Sie
        über neue Angebote zu informieren und auch unseren unternehmerischen
        Zielen näher zu kommen.
      </p>
      <h2 className="text-2xl mt-4">Welche Daten werden verarbeitet?</h2>
      <p>
        Wenn Sie über unsere Website Abonnent unseres Newsletters werden,
        bestätigen Sie per E-Mail die Mitgliedschaft in einer E-Mail-Liste.
        Neben IP-Adresse und E-Mail-Adresse können auch Ihre Anrede, Ihr Name,
        Ihre Adresse und Ihre Telefonnummer gespeichert werden. Allerdings nur,
        wenn Sie dieser Datenspeicherungen zustimmen. Die als solche markierten
        Daten sind notwendig, damit Sie an dem angebotenen Dienst teilnehmen
        können. Die Angabe ist freiwillig, die Nichtangabe führt jedoch dazu,
        dass Sie den Dienst nicht nutzen können. Zusätzlich können etwa auch
        Informationen zu Ihrem Gerät oder zu Ihren bevorzugten Inhalten auf
        unserer Website gespeichert werden. Mehr zur Speicherung von Daten, wenn
        Sie eine Website besuchen, finden Sie im Abschnitt “Automatische
        Datenspeicherung”. Ihre Einwilligungserklärung zeichnen wir auf, damit
        wir stets nachweisen können, dass dieser unseren Gesetzen entspricht.
      </p>
      <h2 className="text-2xl mt-4">Dauer der Datenverarbeitung</h2>
      <p>
        Wenn Sie Ihre E-Mail-Adresse aus unserem E-Mail/Newsletter-Verteiler
        austragen, dürfen wir Ihre Adresse bis zu drei Jahren auf Grundlage
        unserer berechtigten Interessen speichern, damit wir Ihre damalige
        Einwilligung noch nachweisen können. Verarbeiten dürfen wir diese Daten
        nur, wenn wir uns gegen etwaige Ansprüche wehren müssen.
      </p>
      <p>
        Wenn Sie allerdings bestätigen, dass Sie uns die Einwilligung zur
        Newsletter-Anmeldung gegeben haben, können Sie jederzeit einen
        individuellen Löschantrag stellen. Widersprechen Sie der Einwilligung
        dauerhaft, behalten wir uns das Recht vor, Ihre E-Mail-Adresse in einer
        Sperrliste zu speichern. Solange Sie freiwillig unseren Newsletter
        abonniert haben, solange behalten wir selbstverständlich auch Ihre
        E-Mail-Adresse.
      </p>
      <h2 className="text-2xl mt-4">Widerspruchsrecht</h2>
      <p>
        Sie haben jederzeit die Möglichkeit Ihre Newsletter-Anmeldung zu
        kündigen. Dafür müssen Sie lediglich Ihre Einwilligung zur
        Newsletter-Anmeldung widerrufen. Das dauert im Normalfall nur wenige
        Sekunden bzw. einen oder zwei Klicks. Meistens finden Sie direkt am Ende
        jeder E-Mail einen Link, um das Newsletter-Abonnement zu kündigen. Wenn
        der Link im Newsletter wirklich nicht zu finden ist, kontaktieren Sie
        uns bitte per Mail und wir werden Ihr Newsletter-Abo unverzüglich
        kündigen.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Das Versenden unseres Newsletters erfolgt auf Grundlage Ihrer
        Einwilligung (Artikel 6 Abs. 1 lit. a DSGVO). Das heißt, wir dürfen
        Ihnen nur dann einen Newsletter schicken, wenn Sie sich zuvor aktiv
        dafür angemeldet haben. Gegebenenfalls können wir Ihnen auch
        Werbenachrichten senden, sofern Sie unser Kunde geworden sind und der
        Verwendung Ihrer E-Mailadresse für Direktwerbung nicht widersprochen
        haben.
      </p>
      <p>
        Informationen zu speziellen E-Mail-Marketing Diensten und wie diese
        personenbezogene Daten verarbeiten, erfahren Sie – sofern vorhanden – in
        den folgenden Abschnitten.
      </p>
      <h2 className="text-2xl mt-4">
        E-Mail-Marketing mit Brevo (Sendinblue GmbH)
      </h2>
      <p>
        Brevo ist ein Softwareanbieter für E-Mail-Marketing und Customer
        Relationship Management (CRM). Wir haben mit Brevo einen
        Auftragsdaten­verarbeitungs­vertrag (AVV) abgeschlossen. In diesem
        Vertrag verpflichtet sich Brevo dazu, die Daten unserer Nutzer gemäß
        DSGVO und deren Datenschutzbestimmungen zu verarbeiten und zu schützen.
      </p>
      <h2 className="text-2xl mt-4" id="online-marketing-einleitung">
        Online-Marketing Einleitung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>
                Online-Marketing Datenschutzerklärung Zusammenfassung
              </strong>
              <br />
              👥 Betroffene: Besucher der Website
              <br />
              🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des
              Webangebots.
              <br />
              📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
              Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt,
              Navigationsverhalten, Klickverhalten und IP-Adressen enthalten.
              Auch personenbezogene Daten wie Name oder E-Mail-Adresse können
              verarbeitet werden. Mehr Details dazu finden Sie beim jeweils
              eingesetzten Online-Marketing-Tool.
              <br />
              📅 Speicherdauer: abhängig von den eingesetzten
              Online-Marketing-Tools
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was ist Online-Marketing?</h2>
      <p>
        Unter Online-Marketing bezeichnet man alle Maßnahmen, die online
        durchgeführt werden, um Marketingziele wie etwa die Steigerung der
        Markenbekanntheit oder einen Geschäftsabschluss zu erreichen. Weiters
        zielen unserer Online-Marketing-Maßnahmen darauf ab, Menschen auf unsere
        Website aufmerksam zu machen. Um unser Angebot vielen interessierten
        Menschen zeigen zu können, betreiben wir also Online-Marketing. Meistens
        handelt es sich dabei um Onlinewerbung, Content-Marketing oder
        Suchmaschinenoptimierung. Damit wir Online-Marketing effizient und
        zielgerecht einsetzen können, werden auch personenbezogene Daten
        gespeichert und verarbeitet. Die Daten helfen uns einerseits unsere
        Inhalte nur wirklich jenen Personen zu zeigen, die sich dafür auch
        interessieren und andererseits können wir den Werbeerfolg unserer
        Online-Marketing-Maßnahmen messen.
      </p>
      <h2 className="text-2xl mt-4">
        Warum nutzen wir Online-Marketing-Tools?
      </h2>
      <p>
        Wir wollen jedem Menschen, der sich für unser Angebot interessiert,
        unsere Website zeigen. Uns ist bewusst, dass dies ohne bewusst gesetzte
        Maßnahmen nicht möglich ist. Darum machen wir Online-Marketing. Es gibt
        verschiedene Tools, die uns die Arbeit an unseren
        Online-Marketing-Maßnahmen erleichtern und zusätzlich über Daten stets
        Verbesserungsvorschläge liefern. So können wir unsere Kampagnen genauer
        an unsere Zielgruppe richten. Zweck dieser eingesetzten
        Online-Marketing-Tools ist also letztlich die Optimierung unseres
        Angebots.
      </p>
      <h2 className="text-2xl mt-4">Welche Daten werden verarbeitet?</h2>
      <p>
        Damit unser Online-Marketing funktioniert und der Erfolg der Maßnahmen
        gemessen werden kann, werden Userprofile erstellt und Daten
        beispielsweise in Cookies (das sind kleine Text-Dateien) gespeichert.
        Mit Hilfe dieser Daten können wir nicht nur Werbung in klassischer
        Hinsicht schalten, sondern auch direkt auf unserer Website, unsere
        Inhalte so darstellen, wie es Ihnen am liebsten ist. Dafür gibt es
        verschiedene Drittanbieter-Tools, die diese Funktionen anbieten und
        entsprechend auch Daten von Ihnen sammeln und speichern. In den
        benannten Cookies wird zum Beispiel gespeichert, welche Webseiten Sie
        auf unserer Website besucht haben, wie lange Sie diese Seiten angesehen
        haben, welche Links oder Buttons Sie klicken oder von welcher Website
        sie zu uns gekommen sind. Zusätzlich können auch technische
        Informationen gespeichert werden. Etwa Ihre IP-Adresse, welchen Browser
        Sie nutzen, von welchem Endgerät Sie unsere Website besuchen oder die
        Uhrzeit, wann Sie unserer Website aufgerufen haben und wann Sie sie
        wieder verlassen haben. Wenn Sie eingewilligt haben, dass wir auch Ihren
        Standort bestimmen dürfen, können wir auch diesen speichern und
        verarbeiten.
      </p>
      <p>
        Ihre IP-Adresse wird in pseudonymisierter Form (also gekürzt)
        gespeichert. Eindeutige Daten, die Sie als Person direkt identifizieren,
        wie etwa Name, Adresse oder E-Mail-Adresse, werden im Rahmen der Werbe-
        und Online-Marketing-Verfahren auch nur in pseudonymisierter Form
        gespeichert. Wir können Sie also nicht als Person identifizieren,
        sondern wir haben nur die pseudonymisierten, gespeicherten Informationen
        in den Userprofilen gespeichert.
      </p>
      <p>
        Die Cookies können unter Umständen auch auf anderen Websites, die mit
        denselben Werbetools arbeiten, eingesetzt, analysiert und für
        Werbezwecke verwendet werden. Die Daten können dann auch auf den Servern
        der Werbetools-Anbieter gespeichert werden.
      </p>
      <p>
        In Ausnahmefällen können auch eindeutige Daten (Namen, Mail-Adresse
        usw.) in den Userprofilen gespeichert werden. Zu dieser Speicherung
        kommt es etwa, wenn Sie Mitglied eines Social-Media-Kanals sind, dass
        wir für unsere Online-Marketing-Maßnahmen nutzen und das Netzwerk
        bereits früher eingegangene Daten mit dem Userprofil verbindet.
      </p>
      <p>
        Bei allen von uns verwendeten Werbetools, die Daten von Ihnen auf deren
        Servern speichert, erhalten wir immer nur zusammengefasste Informationen
        und nie Daten, die Sie als Einzelperson erkennbar machen. Die Daten
        zeigen lediglich, wie gut gesetzte Werbemaßnahmen funktionierten. Zum
        Beispiel sehen wir, welche Maßnahmen Sie oder andere User dazu bewogen
        haben, auf unsere Website zu kommen und dort eine Dienstleistung oder
        ein Produkt zu erwerben. Anhand der Analysen können wir unser
        Werbeangebot in Zukunft verbessern und noch genauer an die Bedürfnisse
        und Wünsche interessierter Personen anpassen.
      </p>
      <h2 className="text-2xl mt-4">Dauer der Datenverarbeitung</h2>
      <p>
        Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten,
        sofern wir weitere Informationen dazu haben. Generell verarbeiten wir
        personenbezogene Daten nur so lange wie es für die Bereitstellung
        unserer Dienstleistungen und Produkte unbedingt notwendig ist. Daten,
        die in Cookies gespeichert werden, werden unterschiedlich lange
        gespeichert. Manche Cookies werden bereits nach dem Verlassen der
        Website wieder gelöscht, anderen können über einige Jahre in Ihrem
        Browser gespeichert sein. In den jeweiligen Datenschutzerklärungen der
        einzelnen Anbieter erhalten Sie in der Regel genaue Informationen über
        die einzelnen Cookies, die der Anbieter verwendet.
      </p>
      <h2 className="text-2xl mt-4">Widerspruchsrecht</h2>
      <p>
        Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung
        zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das
        funktioniert entweder über unser Cookie-Management-Tool oder über andere
        Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch
        Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten,
        deaktivieren oder löschen. Die Rechtmäßigkeit der Verarbeitung bis zum
        Widerruf bleibt unberührt.
      </p>
      <p>
        Da bei Online-Marketing-Tools in der Regel Cookies zum Einsatz kommen
        können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung
        über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert
        und verarbeitet werden, sollten Sie die Datenschutzerklärungen der
        jeweiligen Tools durchlesen.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Wenn Sie eingewilligt haben, dass Drittanbieter eingesetzt werden
        dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung
        diese Einwilligung. Diese Einwilligung stellt laut
        <strong>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong>
        die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
        sie bei der Erfassung durch Online-Marketing-Tools vorkommen kann, dar.
      </p>
      <p>
        Von unserer Seite besteht zudem ein berechtigtes Interesse,
        Online-Marketing-Maßnahmen in anonymisierter Form zu messen, um mithilfe
        der gewonnenen Daten unser Angebot und unsere Maßnahmen zu optimieren.
        Die dafür entsprechende Rechtsgrundlage ist
        <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>.
        Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung
        erteilt haben.
      </p>
      <p>
        Informationen zu speziellen Online-Marketing-Tools erhalten Sie – sofern
        vorhanden – in den folgenden Abschnitten.
      </p>
      <h2 className="text-2xl mt-4" id="partnerprogramme-einleitung">
        Partnerprogramme Einleitung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>
                Partnerprogramme Datenschutzerklärung Zusammenfassung
              </strong>
              <br />
              👥 Betroffene: Besucher der Website
              <br />
              🤝 Zweck: wirtschaftlicher Erfolg und die Optimierung unserer
              Serviceleistung.
              <br />
              📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
              Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt,
              Navigationsverhalten, Klickverhalten und IP-Adressen enthalten.
              Auch personenbezogene Daten wie Name oder E-Mail-Adresse können
              verarbeitet werden.
              <br />
              📅 Speicherdauer: persönliche Daten werden meist von
              Partnerprogrammen gespeichert, bis diese nicht mehr benötigt
              werden
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was sind Partnerprogramme?</h2>
      <p>
        Wir verwenden auf unserer Website Partnerprogramme unterschiedlicher
        Anbieter. Durch die Verwendung eines Partnerprogramms können Daten von
        Ihnen an den jeweiligen Partnerprogramm-Anbieter übertragen, gespeichert
        und verarbeitet werden. In diesem Datenschutztext geben wir Ihnen einen
        allgemeinen Überblick über die Datenverarbeitung durch Partnerprogramme
        und zeigen Ihnen wie Sie eine Datenübertragung auch unterbinden bzw.
        widerrufen können. Jedes Partnerprogramm (auch Affiliateprogramm
        genannt) basiert auf dem Prinzip der Vermittlungsprovision. Auf unserer
        Website wird ein Link oder eine Werbung samt Link gesetzt und wenn Sie
        sich dafür interessieren und diesen anklicken und ein Produkt oder eine
        Dienstleistung auf diesem Wege erwerben, erhalten wir dafür eine
        Provision (Werbekostenerstattung)
      </p>
      <h2 className="text-2xl mt-4">
        Warum verwenden wir Partnerprogramme auf unserer Website?
      </h2>
      <p>
        Unser Ziel ist es, Ihnen eine angenehme Zeit mit vielen hilfreichen
        Inhalten zu liefern. Dafür stecken wir sehr viel Arbeit und Zeit in die
        Entwicklung unserer Website. Mit Hilfe von Partnerprogrammen haben wir
        die Möglichkeit, für unsere Arbeit auch ein bisschen entlohnt zu werden.
        Jeder Partnerlink hat selbstverständlich immer mit unserem Thema zu tun
        und zeigt Angebote, die Sie interessieren könnten.
      </p>
      <h2 className="text-2xl mt-4">Welche Daten werden verarbeitet?</h2>
      <p>
        Damit nachvollzogen werden kann, ob Sie einen von uns eingesetzten Link
        angeklickt haben, muss der Partnerprogramm-Anbieter erfahren, dass Sie
        es waren, die über unsere Website den Link gefolgt ist. Es muss also
        eine korrekte Zuordnung der eingesetzten Partnerprogramm-Links zu den
        folgenden Aktionen (Geschäftsabschluss, Kauf, Conversion, Impression
        usw.) erfolgen. Nur dann kann auch die Abrechnung der Provisionen
        funktionieren.
      </p>
      <p>
        Damit diese Zuordnung funktioniert, kann einem Link ein Wert angehängt
        werden (in der URL) oder Informationen in Cookies gespeichert werden.
        Darin wird etwa gespeichert, von welcher Seite Sie kommen (Referrer),
        wann Sie auf den Link geklickt haben, eine Kennung unserer Website, um
        welches Angebot es sich handelt und eine User-Kennung.
      </p>
      <p>
        Das bedeutet, sobald Sie mit Produkten und Dienstleistungen eines
        Partnerprogramms interagieren, erhebt dieser Anbieter auch Daten von
        Ihnen. Welche Daten genau gespeichert werden, hängt von den einzelnen
        Anbietern ab. Beispielsweise unterscheidet das Amazon-Partnerprogramm
        zwischen aktiven und automatischen Informationen. Zu den aktiven zählen
        Name, E-Mail-Adresse, Telefonnummer, Alter, Zahlungsinformationen oder
        Standortinformationen. Zu den automatisch gespeicherten Informationen
        zählen in diesem Fall Userverhalten, IP-Adresse, Geräteinformationen und
        die URL.
      </p>
      <h2 className="text-2xl mt-4">Dauer der Datenverarbeitung</h2>
      <p>
        Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten,
        sofern wir weitere Informationen dazu haben. Generell werden
        personenbezogene Daten nur so lange verarbeitet, wie es für die
        Bereitstellung der Dienstleistungen und Produkte notwendig ist. Daten,
        die in Cookies gespeichert werden, werden unterschiedlich lange
        gespeichert. Manche Cookies werden bereits nach dem Verlassen der
        Website wieder gelöscht, andere können, sofern diese nicht aktiv
        gelöscht werden, über einige Jahre in Ihrem Browser gespeichert sein.
        Die genaue Dauer der Datenverarbeitung hängt vom verwendeten Anbieter
        ab, meistens sollten Sie sich auf eine Speicherdauer von mehreren Jahren
        einstellen. In den jeweiligen Datenschutzerklärungen der einzelnen
        Anbieter erhalten Sie in der Regel genaue Informationen über die Dauer
        der Datenverarbeitung.
      </p>
      <h2 className="text-2xl mt-4">Widerspruchsrecht</h2>
      <p>
        Sie haben immer das Recht auf Auskunft, Berichtigung und Löschung Ihrer
        personenbezogenen Daten. Bei Fragen können Sie auch jederzeit
        Verantwortliche des verwendeten Partnerprogramm-Anbieters kontaktieren.
        Kontaktdaten finden Sie entweder in unserer spezifischen
        Datenschutzerklärung oder auf der Website des entsprechenden Anbieters.
      </p>
      <p>
        Cookies, die Anbieter für ihre Funktionen verwenden, können Sie in Ihrem
        Browser löschen, deaktivieren oder verwalten. Je nachdem welchen Browser
        Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Wenn Sie eingewilligt haben, dass Partnerprogramme eingesetzt werden
        dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung
        diese Einwilligung. Diese Einwilligung stellt laut
        <strong>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die
        Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie
        bei der Erfassung durch ein Partnerprogramm vorkommen kann, dar.
      </p>
      <p>
        Von unserer Seite besteht zudem ein berechtigtes Interesse, ein
        Partnerprogramm zu verwenden, um unser Online-Service und unsere
        Marketingmaßnahmen zu optimieren. Die dafür entsprechende
        Rechtsgrundlage ist
        <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>.
        Wir setzen das Partnerprogramm gleichwohl nur ein, soweit Sie eine
        Einwilligung erteilt haben.
      </p>
      <p>
        Informationen zu speziellen Partnerprogramme, erhalten Sie – sofern
        vorhanden – in den folgenden Abschnitten.
      </p>
      <h2
        className="text-2xl mt-4"
        id="amazon-partnerprogramm-datenschutzerklaerung"
      >
        Amazon-Partnerprogramm Datenschutzerklärung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>
                Amazon-Partnerprogramm Datenschutzerklärung Zusammenfassung
              </strong>
              <br />
              👥 Betroffene: Besucher der Website
              <br />
              🤝 Zweck: wirtschaftlicher Erfolg und die Optimierung unserer
              Serviceleistung.
              <br />
              📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
              Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt,
              Navigationsverhalten, Klickverhalten und IP-Adressen enthalten.
              Auch personenbezogene Daten wie Name oder E-Mail-Adresse können
              verarbeitet werden.
              <br />
              📅 Speicherdauer: persönliche Daten werden von Amazon gespeichert,
              bis diese nicht mehr benötigt werden
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was ist das Amazon-Partnerprogramm?</h2>
      <p>
        Wir verwenden auf unserer Website das Amazon-Partnerprogramm des
        Unternehmens Amazon.com, Inc. Die verantwortlichen Stellen im Sinne der
        Datenschutzerklärung sind die Amazon Europe Core S.à.r.l., die Amazon EU
        S.à.r.l, die Amazon Services Europe S.à.r.l. und die Amazon Media EU
        S.à.r.l., alle vier ansässig 5, Rue Plaetis, L-2338 Luxemburg sowie
        Amazon Instant Video Germany GmbH, Domagkstr. 28, 80807 München. Als
        Datenverarbeiter wird die Amazon Deutschland Services GmbH,
        Marcel-Breuer-Str. 12, 80807 München, tätig. Durch die Verwendung dieses
        Amazon-Partnerprogramms können Daten von Ihnen an Amazon übertragen,
        gespeichert und verarbeitet werden.
      </p>
      <p>
        In dieser Datenschutzerklärung informieren wir Sie um welche Daten es
        sich handelt, warum wir das Programm verwenden und wie Sie die
        Datenübertragung verwalten bzw. unterbinden können.
      </p>
      <p>
        Das Amazon-Partnerprogramm ist ein Affiliate-Marketing-Programm des
        Online-Versandunternehmens{" "}
        <Link
          to="https://www.amazon.de/ref=as_li_ss_tl?site-redirect=at&linkCode=ll2&tag=thetraffic-21&linkId=16a65fb03b8cb39206283c5345d87944&language=de_DE&tid=312394123"
          target="_blank"
          rel="follow noopener noreferrer"
        >
          Amazon.de
        </Link>{" "}
        . Wie jedes Affiliate-Programm basiert auch das Amazon-Partnerprogramm
        auf dem Prinzip der Vermittlungsprovision. Amazon bzw. wir platzieren
        auf unserer Website Werbung oder Partnerlinks und wenn Sie darauf
        klicken und ein Produkt über Amazon kaufen, erhalten wir eine
        Werbekostenerstattung (Provision).
      </p>
      <h2 className="text-2xl mt-4">
        Warum verwenden wir das Amazon-Partnerprogramm auf unserer Website?
      </h2>
      <p>
        Unser Ziel ist es Ihnen eine angenehme Zeit mit vielen hilfreichen
        Inhalten zu liefern. Dafür stecken wir sehr viel Arbeit und Energie in
        die Entwicklung unserer Website. Mit Hilfe des Amazon-Partnerprogramms
        haben wir die Möglichkeit, für unsere Arbeit auch ein bisschen entlohnt
        zu werden. Jeder Partnerlink zu Amazon hat selbstverständlich immer mit
        unserem Thema zu tun und zeigt Angebote, die Sie interessieren könnten.
      </p>
      <h2 className="text-2xl mt-4">
        Welche Daten werden durch das Amazon-Partnerprogramm gespeichert?
      </h2>
      <p>
        Sobald Sie mit den Produkten und Dienstleistungen von Amazon
        interagieren, erhebt Amazon Daten von Ihnen. Amazon unterscheidet
        zwischen Informationen, die Sie aktiv dem Unternehmen geben und
        Informationen, die automatisch gesammelt und gespeichert werden. Zu den
        “aktiven Informationen” zählen zum Beispiel Name, E-Mail-Adresse,
        Telefonnummer, Alter, Zahlungsinformationen oder Standortinformationen.
        Sogenannte „automatische Informationen“ werden in erster Linie über
        Cookies gespeichert. Dazu zählen Informationen zum Userverhalten,
        IP-Adresse, Gerätinformationen (Browsertyp, Standort, Betriebssysteme)
        oder die URL. Amazon speichert weiters auch den Clickstream. Damit ist
        der Weg (Reihenfolge der Seiten) gemeint, den Sie als User zurücklegen,
        um zu einem Produkt zu kommen. Auch um die Herkunft einer Bestellung
        nachvollziehen zu können, speichert Amazon Cookies in Ihrem Browser. So
        erkennt das Unternehmen, dass Sie über unsere Website eine
        Amazon-Werbeanzeige oder einen Partnerlink angeklickt haben.
      </p>
      <p>
        Wenn Sie ein Amazon-Konto haben und angemeldet sind, während Sie auf
        unserer Webseite surfen, können die erhobenen Daten Ihrem Account
        zugewiesen werden. Das verhindern Sie, indem Sie sich bei Amazon
        abmelden, bevor Sie auf unserer Website surfen.
      </p>
      <p>
        Hier zeigen wir Ihnen beispielhafte Cookies, die in Ihrem Browser
        gesetzt werden, wenn Sie auf unserer Website auf einen Amazon-Link
        klicken.
      </p>
      <p>
        <strong>Name</strong>: uid
        <br />
        <strong>Wert: </strong>3230928052675285215312394123-9
        <br />
        <strong>Verwendungszweck: </strong>Dieses Cookie speichert eine
        eindeutige User-ID und sammelt Informationen über Ihre Websiteaktivität.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Monaten
      </p>
      <p>
        <strong>Name</strong>: ad-id
        <br />
        <strong>Wert: </strong>AyDaInRV1k-Lk59xSnp7h5o
        <br />
        <strong>Verwendungszweck: </strong>Dieses Cookie wird von
        amazon-adsystem.com zur Verfügung gestellt und dient dem Unternehmen für
        verschiedene Werbezwecke.
        <br />
        <strong>Ablaufdatum:</strong> nach 8 Monaten
      </p>
      <p>
        <strong>Name</strong>: uuid2
        <br />
        <strong>Wert: </strong>8965834524520213028312394123-2
        <br />
        <strong>Verwendungszweck: </strong>Dieses Cookie ermöglicht gezielte und
        interessensbezogene Werbung über die AppNexus-Plattform. Das Cookie
        sammelt und speichert über die IP-Adresse beispielsweise anonyme Daten
        darüber, welche Werbung Sie angeklickt haben und welche Seiten Sie
        aufgerufen haben.
        <br />
        <strong>Ablaufdatum:</strong> nach 3 Monaten
      </p>
      <p>
        <strong>Name</strong>: session-id
        <br />
        <strong>Wert: </strong>262-0272718-2582202312394123-1
        <br />
        <strong>Verwendungszweck: </strong>Dieses Cookie speichert eine
        eindeutige User-ID, die der Server Ihnen für die Dauer eines
        Websitebesuchs (Session) zuweist. Besuchen Sie dieselbe Seite wieder,
        werden die darin gespeichert Information wieder abgerufen.
        <br />
        <strong>Ablaufdatum:</strong> nach 15 Jahren
      </p>
      <p>
        <strong>Name</strong>: APID
        <br />
        <strong>Wert: </strong>UP9801199c-4bee-11ea-931d-02e8e13f0574
        <br />
        <strong>Verwendungszweck: </strong>Dieses Cookie speichert Informationen
        darüber, wie Sie eine Website nutzen und welche Werbung Sie vor dem
        Websitebesuch angesehen haben.
        <br />
        <strong>Ablaufdatum:</strong> nach einem Jahr
      </p>
      <p>
        <strong>Name</strong>: session-id-time
        <br />
        <strong>Wert: </strong>
        tb:s-STNY7ZS65H5335FZEVPE|1581329862486&t:1581329864300&adb:adblk_no
        <br />
        <strong>Verwendungszweck: </strong>Dieses Cookie erfasst die Zeit, die
        Sie mit einer eindeutigen Cookie-ID auf einer Webseite verbringen.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name</strong>: csm-hit
        <br />
        <strong>Wert: </strong>2082754801l
        <br />
        <strong>Verwendungszweck: </strong>Wir könnten über dieses Cookie keine
        genauen Informationen in Erfahrung bringen.
        <br />
        <strong>Ablaufdatum:</strong> nach 15 Jahren
      </p>
      <p>
        <strong>Anmerkung: </strong>Bitte beachten Sie, dass diese Liste
        lediglich Cookie-Beispiele zeigt und keinen Anspruch auf Vollständigkeit
        erheben kann.
      </p>
      <p>
        Amazon nutzt diese erhaltenen Informationen, um Werbeanzeigen genauer an
        die Interessen der User abzustimmen.
      </p>
      <h2 className="text-2xl mt-4">
        Wie lange und wo werden die Daten gespeichert?
      </h2>
      <p>
        Persönliche Daten werden von Amazon so lange gespeichert, wie es für die
        geschäftlichen Dienste von Amazon erforderlich ist oder aus rechtlichen
        Gründen notwendig ist. Da das Unternehmen Amazon seinen Hauptsitz in den
        USA hat, werden die gesammelten Daten auch auf amerikanischen Servern
        gespeichert.
      </p>
      <h2 className="text-2xl mt-4">
        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
      </h2>
      <p>
        Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten
        zuzugreifen und sie auch zu löschen. Wenn Sie einen Amazon-Account
        besitzen, können Sie in Ihrem Account viele der erhobenen Daten
        verwalten oder löschen.
      </p>
      <p>
        Eine weitere Option, die Datenverarbeitung und -speicherung durch Amazon
        nach Ihren Vorlieben zu verwalten, bietet Ihr Browser. Dort können Sie
        Cookies verwalten, deaktivieren oder löschen. Dies funktioniert bei
        jedem Browser ein bisschen anders. Unter dem Abschnitt „Cookies“ finden
        Sie die entsprechenden Links zu den jeweiligen Anleitungen der
        bekanntesten Browser.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Wenn Sie eingewilligt haben, dass das Amazon-Partnerprogramm eingesetzt
        werden darf, ist die Rechtsgrundlage der entsprechenden
        Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut
        <strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong>
        die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
        sie bei der Erfassung durch das Amazon-Partnerprogramm vorkommen kann,
        dar.
      </p>
      <p>
        Von unserer Seite besteht zudem ein berechtigtes Interesse, das
        Amazon-Partnerprogramm zu verwenden, um unser Online-Service und unsere
        Marketingmaßnahmen zu optimieren. Die dafür entsprechende
        Rechtsgrundlage ist
        <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>.
        Wir setzen das Amazon-Partnerprogramm gleichwohl nur ein, soweit Sie
        eine Einwilligung erteilt haben.
      </p>
      <p>
        Amazon verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen
        darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein
        angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies
        kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der
        Datenverarbeitung einhergehen.
      </p>
      <p>
        Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
        Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
        Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
        dorthin verwendet Amazon sogenannte Standardvertragsklauseln (= Art. 46.
        Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual
        Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen
        und sollen sicherstellen, dass Ihre Daten auch dann den europäischen
        Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
        beispielsweise in die USA) überliefert und dort gespeichert werden.
        Durch diese Klauseln verpflichtet sich Amazon, bei der Verarbeitung
        Ihrer relevanten Daten, das europäische Datenschutzniveau zu wahren,
        selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet
        werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der
        EU-Kommission. Sie finden den Beschluss und die entsprechenden
        Standardvertragsklauseln u.a. hier:{" "}
        <Link
          to="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
          target="_blank"
          rel="follow noopener"
        >
          https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
        </Link>{" "}
      </p>
      <p>
        Die Amazon Datenverarbeitungsbedingung (AWS GDPR DATA PROCESSING),
        welche den Standardvertragsklauseln entsprechen, finden Sie unter{" "}
        <Link
          to="https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf"
          target="_blank"
          rel="follow noopener"
        >
          https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf
        </Link>{" "}
        .
      </p>
      <p>
        Wir hoffen, wir haben Ihnen die wichtigsten Informationen über die
        Datenübertragung durch die Verwendung von dem Amazon-Partnerprogramm
        nähergebracht. Mehr Informationen finden Sie unter{" "}
        <Link
          to="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010
        </Link>{" "}
        .
      </p>
      <h2
        className="text-2xl mt-4"
        id="ebay-partner-network-datenschutzerklaerung"
      >
        eBay Partner Network Datenschutzerklärung
      </h2>
      <p>
        Wir nutzen für unsere Website das Partnerprogramm von eBay.
        Dienstanbieter ist das amerikanische Unternehmen: eBay Partner Network,
        Inc., 2145 Hamilton Ave., San Jose, CA 95125, USA.
      </p>
      <p>
        eBay verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass
        nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes
        Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit
        verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der
        Datenverarbeitung einhergehen.
      </p>
      <p>
        Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
        Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
        Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
        dorthin verwendet eBay von der EU-Kommission genehmigte
        Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln
        verpflichten eBay, das EU-Datenschutzniveau bei der Verarbeitung
        relevanter Daten auch außerhalb der EU zu wahren. Diese Klauseln
        basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden
        den Beschluss sowie die Klauseln u.a. hier:{" "}
        <Link to="https://germany.representation.ec.europa.eu/index_de">
          https://germany.representation.ec.europa.eu/index_de
        </Link>{" "}
      </p>
      <p>
        Mehr über die Daten, die durch die Verwendung von eBay Partner Network
        verarbeitet werden, erfahren Sie in der Datenschutzerklärung auf{" "}
        <Link
          to="https://partnernetwork.ebay.de/page/network-agreement#privacy-policy"
          target="_blank"
          rel="follow noopener"
        >
          https://partnernetwork.ebay.de/page/network-agreement#privacy-policy
        </Link>{" "}
        .
      </p>
      <h2 className="text-2xl mt-4" id="content-delivery-networks-einleitung">
        Content Delivery Networks Einleitung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>
                Content Delivery Networks Datenschutzerklärung Zusammenfassung
              </strong>
              <br />
              👥 Betroffene: Besucher der Website
              <br />
              🤝 Zweck: Optimierung unserer Serviceleistung (um die Website
              schneller laden zu können)
              <br />
              📓 Verarbeitete Daten: Daten wie etwa Ihre IP-Adresse
              <br />
              Mehr Details dazu finden Sie weiter unten und den einzelnen
              Datenschutztexten.
              <br />
              📅 Speicherdauer: meisten werden die Daten solange gespeichert,
              bis sie zur Erfüllung der Dienstleistung nicht mehr benötigt
              werden
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was ist ein Content Delivery Network?</h2>
      <p>
        Wir nutzen auf unserer Website ein sogenanntes Content Delivery Network.
        Meistens wird ein solchen Netzwerk nur CDN genannt. Ein CDN hilft uns,
        unsere Website, unabhängig von Ihrem Standort, schnell und problemlos zu
        laden. Dabei werden auch personenbezogene Daten von Ihnen auf den
        Servern des eingesetzten CDN-Anbieters gespeichert, verwaltet und
        verarbeitet. Nachfolgend gehen wir allgemein näher auf den Dienst und
        dessen Datenverarbeitung ein. Genaue Informationen über den Umgang mit
        Ihren Daten finden Sie in der jeweiligen Datenschutzerklärung des
        Anbieters.
      </p>
      <p>
        Jedes Content Delivery Network (CDN) ist ein Netzwerk regional
        verteilter Server, die alle über das Internet miteinander verbunden
        sind. Über dieses Netzwerk können Inhalte von Websites (speziell auch
        sehr große Dateien) auch bei großen Lastspitzen schnell und reibungslos
        ausgeliefert werden. Das CDN legt dafür eine Kopie unserer Website auf
        Ihren Servern an. Da diese Server weltweit verteilt sind, kann die
        Website schnell ausgeliefert werden. Die Datenübertragung zu Ihrem
        Browser wird folglich durch das CDN deutlich verkürzt.
      </p>
      <h2 className="text-2xl mt-4">
        Warum verwenden wir ein Content Delivery Network für unsere Website?
      </h2>
      <p>
        Eine schnell ladende Website ist Teil unserer Dienstleistung. Wir wissen
        natürlich, wie nervig es ist, wenn eine Website im Schneckentempo lädt.
        Meist verliert man sogar die Geduld und sucht das Weite, bevor die
        Website vollständig geladen ist. Das wollen wir natürlich vermeiden.
        Daher gehört eine schnell ladende Website ganz selbstverständlich zu
        unserem Websiteangebot. Mit einem Content Delivery Network wird in Ihrem
        Browser unsere Website deutlich schneller geladen. Besonders hilfreich
        ist der Einsatz des CDNs wenn Sie im Ausland sind, weil die Website von
        einem Server in Ihrer Nähe ausgeliefert wird.
      </p>
      <h2 className="text-2xl mt-4">Welche Daten werden verarbeitet?</h2>
      <p>
        Wenn Sie eine Website bzw. die Inhalte einer Website anfordern und diese
        in einem CDN zwischengespeichert sind, leitet das CDN die Anforderung an
        den von Ihnen am nächsten gelegenen Server und dieser liefert die
        Inhalte aus. Content Delivery Networks sind so aufgebaut, dass
        JavaScript-Bibliotheken heruntergeladen werden können und auf npm- und
        Github-Servern gehostet werden. Alternativ können bei den meisten CDNs
        auch WordPress-Plugins geladen werden, wenn diese auf{" "}
        <Link to="https://wordpress.org/" target="_blank" rel="noopener">
          WordPress.org
        </Link>{" "}
        gehostet werden. Ihr Browser kann personenbezogene Daten an das von uns
        eingesetzte Content Delivery Network senden. Dabei handelt es sich etwa
        um Daten wie IP-Adresse, Browsertyp, Browserversion, welche Webseite
        geladen wird oder Uhrzeit und Datum des Seitenbesuchs. Diese Daten
        werden vom CDN gesammelt und auch gespeichert. Ob zur Datenspeicherung
        Cookies verwendet werden, hängt von dem eingesetzten Network ab. Bitte
        lesen Sie sich dafür die Datenschutztexte des jeweiligen Dienstes durch.
      </p>
      <h2 className="text-2xl mt-4">Widerspruchsrecht</h2>
      <p>
        Falls Sie diese Datenübertragung vollkommen unterbinden wollen, können
        Sie einen JavaScript-Blocker (siehe beispielsweise{" "}
        <Link to="https://noscript.net/" target="_blank" rel="noopener">
          https://noscript.net/
        </Link>{" "}
        ) auf Ihrem PC installieren. Natürlich kann dann unsere Website nicht
        mehr das gewohnte Service (wie beispielsweise eine schnelle
        Ladegeschwindigkeit) bieten.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Wenn Sie eingewilligt haben, dass ein Content Delivery Network
        eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden
        Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut
        <strong>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die
        Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie
        bei der Erfassung durch ein Content Delivery Network vorkommen kann,
        dar.
      </p>
      <p>
        Von unserer Seite besteht zudem ein berechtigtes Interesse, ein Content
        Delivery Network zu verwenden, um unser Online-Service zu optimieren und
        sicherer zu machen. Die dafür entsprechende Rechtsgrundlage ist
        <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>.
        Wir setzen das Tool gleichwohl nur ein, soweit Sie eine Einwilligung
        erteilt haben.
      </p>
      <p>
        Informationen zu speziellen Content Delivery Network erhalten Sie –
        sofern vorhanden – in den folgenden Abschnitten.
      </p>
      <h2
        className="text-2xl mt-4"
        id="cookie-consent-management-platform-einleitung"
      >
        Cookie Consent Management Platform Einleitung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>
                Cookie Consent Management Platform Zusammenfassung
              </strong>
              <br />
              👥 Betroffene: Website Besucher
              <br />
              🤝 Zweck: Einholung und Verwaltung der Zustimmung zu bestimmten
              Cookies und somit dem Einsatz bestimmter Tools
              <br />
              📓 Verarbeitete Daten: Daten zur Verwaltung der eingestellten
              Cookie-Einstellungen wie IP-Adresse, Zeitpunkt der Zustimmung, Art
              der Zustimmung, einzelne Zustimmungen. Mehr Details dazu finden
              Sie beim jeweils eingesetzten Tool.
              <br />
              📅 Speicherdauer: Hängt vom eingesetzten Tool ab, man muss sich
              auf Zeiträume von mehreren Jahren einstellen
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit.f DSGVO (berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">
        Was ist eine Cookie Consent Manangement Platform?
      </h2>
      <p>
        Wir verwenden auf unserer Website eine Consent Management Platform (CMP)
        Software, die uns und Ihnen den korrekten und sicheren Umgang mit
        verwendeten Skripten und Cookies erleichtert. Die Software erstellt
        automatisch ein Cookie-Popup, scannt und kontrolliert alle Skripts und
        Cookies, bietet eine datenschutzrechtlich notwendige Cookie-Einwilligung
        für Sie und hilft uns und Ihnen den Überblick über alle Cookies zu
        behalten. Bei den meisten Cookie Consent Management Tools werden alle
        vorhandenen Cookies identifiziert und kategorisiert. Sie als
        Websitebesucher entscheiden dann selbst, ob und welche Skripte und
        Cookies Sie zulassen oder nicht zulassen. Die folgende Grafik stellt die
        Beziehung zwischen Browser, Webserver und CMP dar.
      </p>
      <p>
        <img
          role="img"
          src="https://www.adsimple.at/wp-content/uploads/2021/03/consent-management-platform-overview.svg"
          alt="Consent Management Platform Überblick"
          width="100%"
        />
      </p>
      <h2 className="text-2xl mt-4">
        Warum verwenden wir ein Cookie-Management-Tool?
      </h2>
      <p>
        Unser Ziel ist es, Ihnen im Bereich Datenschutz die bestmögliche
        Transparenz zu bieten. Zudem sind wir dazu auch rechtlich verpflichtet.
        Wir wollen Sie über alle Tools und alle Cookies, die Daten von Ihnen
        speichern und verarbeiten können, so gut wie möglich aufklären. Es ist
        auch Ihr Recht, selbst zu entscheiden, welche Cookies Sie akzeptieren
        und welche nicht. Um Ihnen dieses Recht zu garantieren, müssen wir
        zuerst genau wissen, welche Cookies überhaupt auf unserer Website
        gelandet sind. Dank eines Cookie-Management-Tools, welches die Website
        regelmäßig nach allen vorhandenen Cookies scannt, wissen wir über alle
        Cookies Bescheid und können Ihnen DSGVO-konform Auskunft darüber geben.
        Über das Einwilligungssystem können Sie dann Cookies akzeptieren oder
        ablehnen.
      </p>
      <h2 className="text-2xl mt-4">Welche Daten werden verarbeitet?</h2>
      <p>
        Im Rahmen unseres Cookie-Management-Tools können Sie jedes einzelnen
        Cookies selbst verwalten und haben die vollständige Kontrolle über die
        Speicherung und Verarbeitung Ihrer Daten. Die Erklärung Ihrer
        Einwilligung wird gespeichert, damit wir Sie nicht bei jedem neuen
        Besuch unserer Website abfragen müssen und wir Ihre Einwilligung, wenn
        gesetzlich nötig, auch nachweisen können. Gespeichert wird dies entweder
        in einem Opt-in-Cookie oder auf einem Server. Je nach Anbieter des
        Cookie-Management-Tools variiert Speicherdauer Ihrer
        Cookie-Einwilligung. Meist werden diese Daten (etwa pseudonyme User-ID,
        Einwilligungs-Zeitpunkt, Detailangaben zu den Cookie-Kategorien oder
        Tools, Browser, Gerätinformationen) bis zu zwei Jahren gespeichert.
      </p>
      <h2 className="text-2xl mt-4">Dauer der Datenverarbeitung</h2>
      <p>
        Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten,
        sofern wir weitere Informationen dazu haben. Generell verarbeiten wir
        personenbezogene Daten nur so lange wie es für die Bereitstellung
        unserer Dienstleistungen und Produkte unbedingt notwendig ist. Daten,
        die in Cookies gespeichert werden, werden unterschiedlich lange
        gespeichert. Manche Cookies werden bereits nach dem Verlassen der
        Website wieder gelöscht, andere können über einige Jahre in Ihrem
        Browser gespeichert sein. Die genaue Dauer der Datenverarbeitung hängt
        vom verwendeten Tool ab, meistens sollten Sie sich auf eine
        Speicherdauer von mehreren Jahren einstellen. In den jeweiligen
        Datenschutzerklärungen der einzelnen Anbieter erhalten Sie in der Regel
        genaue Informationen über die Dauer der Datenverarbeitung.
      </p>
      <h2 className="text-2xl mt-4">Widerspruchsrecht</h2>
      <p>
        Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung
        zur Verwendung von Cookies zu widerrufen. Das funktioniert entweder über
        unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum
        Bespiel können Sie auch die Datenerfassung durch Cookies verhindern,
        indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder
        löschen.
      </p>
      <p>
        Informationen zu speziellen Cookie-Management-Tools, erfahren Sie –
        sofern vorhanden – in den folgenden Abschnitten.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Wenn Sie Cookies zustimmen, werden über diese Cookies personenbezogene
        Daten von Ihnen verarbeitet und gespeichert. Falls wir durch Ihre
        <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) Cookies
        verwenden dürfen, ist diese Einwilligung auch gleichzeitig die
        Rechtsgrundlage für die Verwendung von Cookies bzw. die Verarbeitung
        Ihrer Daten. Um die Einwilligung zu Cookies verwalten zu können und
        Ihnen die Einwilligung ermöglichen zu können, kommt eine
        Cookie-Consent-Management-Platform-Software zum Einsatz. Der Einsatz
        dieser Software ermöglicht uns, die Website auf effiziente Weise
        rechtskonform zu betreiben, was ein
        <strong>berechtigtes Interesse</strong> (Artikel 6 Abs. 1 lit. f DSGVO)
        darstellt.
      </p>
      <h2 className="text-2xl mt-4" id="externe-onlineplattformen-einleitung">
        Externe Onlineplattformen Einleitung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>
                Externe Onlineplattformen Datenschutzerklärung Zusammenfassung
              </strong>
              <br />
              👥 Betroffene: Besucher der Website bzw. Besucher der externen
              Onlineplattformen
              <br />
              🤝 Zweck: Darstellung, Bewertung und Optimierung unserer
              Serviceleistung, Kontakt zu Besuchern, Interessenten
              <br />
              📓 Verarbeitete Daten: Daten wie etwa Telefonnummern,
              E-Mail-Adressen, Kontaktdaten, Daten zum Nutzerverhalten,
              Informationen zu Ihrem Gerät und Ihre IP-Adresse.
              <br />
              Mehr Details dazu finden Sie bei der jeweils eingesetzten
              Plattform.
              <br />
              📅 Speicherdauer: abhängig von den verwendeten Plattformen
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was sind externe Onlineplattformen?</h2>
      <p>
        Um unsere Leistungen oder Produkte auch außerhalb unserer Website
        anbieten zu können, nutzen wir auch externe Plattformen. Dabei handelt
        es sich meist um Online-Marktplätze wie etwa Amazon oder eBay oder
        Bewertungsplattformen wie TrustPilot. Neben unserer Verantwortlichkeit
        des Datenschutzes gelten auch die Datenschutzbestimmungen der von uns
        verwendeten externen Plattformen. Dies ist speziell dann der Fall, wenn
        über die Plattform unsere Produkte erworben werden. Also wenn es einen
        Zahlungsvorgang gibt. Weiters nützen die meisten Plattformen Ihre Daten
        auch, um die eigenen Marketingmaßnahmen zu optimieren. Beispielsweise
        kann die Plattform, mit Hilfe erhobener Daten, Werbeanzeigen genau an
        die Interessen der Kunden und Websitebesucher zuschneiden.
      </p>
      <h2 className="text-2xl mt-4">
        Warum nutzen wir externe Onlineplattformen?
      </h2>
      <p>
        Neben unserer Website wollen wir unser Angebot auch auf anderen
        Plattformen anbieten, um mehr Kunden unser Angebot näher zu bringen.
        Externe Online-Marktplätze wie etwa Amazon oder Ebay bieten große
        Verkaufswebsites und Bewertungsportale wie TrustPilot ermöglichen es die
        Reputation unserer Dienstleistungen darzustellen. Es kann auch
        vorkommen, dass eingebaute Elemente auf unserer Seite zu einer externen
        Onlineplattform leiten. Daten, die von der verwendeten Onlineplattform
        verarbeitet und gespeichert werden, dienen dem Unternehmen einerseits
        den Zahlungsvorgang zu protokollieren, andererseits aber auch um
        Webanalysen durchführen zu können.
      </p>
      <p>
        Ziel dieser Analysen ist es, genauere und personenbezogene Marketing-
        und Werbestrategien entwickeln zu können. Abhängig von Ihrem Verhalten
        auf einer Plattform, können mit Hilfe der ausgewerteten Daten, passende
        Rückschlüsse auf Ihre Interessen getroffen werden und sogenannte
        Userprofile erstellt werden. So ist es den Plattformen auch möglich,
        Ihnen maßgeschneiderte Werbeanzeigen bzw. Produkte zu präsentieren.
        Meistens werden für diesen Zweck Cookies in Ihrem Browser gesetzt, die
        Daten zu Ihrem Nutzungsverhalten speichern.
      </p>
      <p>
        Bitte beachten Sie, dass bei der Nutzung der Plattformen oder unserer
        eingebauten Elemente auch Daten von Ihnen außerhalb der Europäischen
        Union verarbeitet werden können, da Onlineplattformen, beispielsweise
        Amazon oder eBay, amerikanische Unternehmen sind. Dadurch können Sie
        möglicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten
        nicht mehr so leicht einfordern bzw. durchsetzen.
      </p>
      <h2 className="text-2xl mt-4">Welche Daten werden verarbeitet?</h2>
      <p>
        Welche Daten genau gespeichert und verarbeitet werden, hängt von der
        jeweiligen externen Plattform ab. Aber für gewöhnlich handelt es sich um
        Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Sie in ein
        Kontaktformular eingeben, Nutzerdaten wie zum Beispiel welche Buttons
        Sie klicken, wann Sie welche Seiten besucht haben, Informationen zu
        Ihrem Gerät und Ihre IP-Adresse. Sehr häufig werden die meisten dieser
        Daten in Cookies gespeichert. Wenn Sie bei einer externen Plattform ein
        eigenes Profil haben und dort auch angemeldet sind, können Daten mit dem
        Profil verknüpft werden. Die erhobenen Daten werden auf den Servern der
        verwendeten Plattformen gespeichert und dort verarbeitet. Wie eine
        externe Plattform Daten genau speichert, verwaltet und verarbeitet,
        erfahren Sie in der jeweiligen Datenschutzerklärung. Falls Sie zur
        Datenspeicherung und Datenverarbeitung Fragen haben oder entsprechende
        Rechte geltend machen wollen, empfehlen wir Ihnen, sich direkt an die
        Plattform zu wenden.
      </p>
      <h2 className="text-2xl mt-4">Dauer der Datenverarbeitung</h2>
      <p>
        Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten,
        sofern wir weitere Informationen dazu haben. Beispielsweise speichert
        Amazon Daten, bis sie für den eigenen Zweck nicht mehr benötigt werden.
        Generell verarbeiten wir personenbezogene Daten nur so lange wie es für
        die Bereitstellung unserer Dienstleistungen und Produkte unbedingt
        notwendig ist.
      </p>
      <h2 className="text-2xl mt-4">Widerspruchsrecht</h2>
      <p>
        Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung
        zur Verwendung von Cookies zu widerrufen. Das funktioniert entweder über
        unser Cookie-Management-Tool oder über Opt-Out-Funktionen bei der
        jeweiligen externen Plattform. Weiters können Sie auch die
        Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die
        Cookies verwalten, deaktivieren oder löschen.
      </p>
      <p>
        Da Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere
        allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche
        Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie
        die Datenschutzerklärungen der jeweiligen externen Plattformen
        durchlesen.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Wenn Sie eingewilligt haben, dass Daten von Ihnen durch externe
        Plattformen verarbeitet und gespeichert werden können, gilt diese
        <strong>Einwilligung</strong> als Rechtsgrundlage der Datenverarbeitung{" "}
        <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre
        Daten bei Vorliegen einer Einwilligung auch auf Grundlage eines
        <strong>berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO)</strong>
        an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden
        und Geschäftspartnern gespeichert und verarbeitet. Falls wir
        eingebundene Elemente externer Plattformen auf unser Website haben,
        setzen wir diese gleichwohl nur ein, soweit Sie eine Einwilligung
        erteilt haben.
      </p>
      <p>
        Informationen zu speziellen externen Plattformen erfahren Sie – sofern
        vorhanden – in den folgenden Abschnitten.
      </p>
      <h2 className="text-2xl mt-4" id="amazon-europe-datenschutzerklaerung">
        Amazon (Europe) Datenschutzerklärung
      </h2>
      <p>
        Wir nutzen auch die Online-Handelsplattform Amazon (Europe).
        Dienstanbieter ist das amerikanische Unternehmen Amazon Inc. Für den
        europäischen Raum ist das Unternehmen Amazon Europe Core S.à r.l.
        (Société à responsabilité limitée), 38 avenue John F. Kennedy, L-1855
        Luxemburg verantwortlich.
      </p>
      <p>
        Amazon verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen
        darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein
        angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies
        kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der
        Datenverarbeitung einhergehen.
      </p>
      <p>
        Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
        Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
        Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
        dorthin verwendet Amazon sogenannte Standardvertragsklauseln (= Art. 46.
        Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual
        Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen
        und sollen sicherstellen, dass Ihre Daten auch dann den europäischen
        Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
        beispielsweise in die USA) überliefert und dort gespeichert werden.
        Durch diese Klauseln verpflichtet sich Amazon, bei der Verarbeitung
        Ihrer relevanten Daten, das europäische Datenschutzniveau zu wahren,
        selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet
        werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der
        EU-Kommission. Sie finden den Beschluss und die entsprechenden
        Standardvertragsklauseln u.a. hier:{" "}
        <Link
          to="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
          target="_blank"
          rel="follow noopener"
        >
          https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
        </Link>{" "}
      </p>
      <p>
        Die Amazon Datenverarbeitungsbedingung (AWS GDPR DATA PROCESSING),
        welche den Standardvertragsklauseln entsprechen, finden Sie unter{" "}
        <Link
          to="https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf"
          target="_blank"
          rel="follow noopener"
        >
          https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf
        </Link>{" "}
        .
      </p>
      <p>
        Mehr über die Daten, die durch die Verwendung von Amazon verarbeitet
        werden, erfahren Sie in der Datenschutzerklärung auf{" "}
        <Link
          to="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010&ref_=footer_privacy"
          target="_blank"
          rel="follow noopener"
        >
          https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010&ref_=footer_privacy
        </Link>{" "}
        <u>.</u>
      </p>
      <h2 className="text-2xl mt-4" id="ebay-datenschutzerklaerung">
        eBay Datenschutzerklärung
      </h2>
      <p>
        Wir nutzen die Online-Handelsplattform eBay. Dienstanbieter ist das
        amerikanische Unternehmen eBay Inc., 2025 Hamilton Avenue, San Jose, CA
        95125, USA.
      </p>
      <p>
        eBay verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass
        nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes
        Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit
        verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der
        Datenverarbeitung einhergehen.
      </p>
      <p>
        Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
        Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
        Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
        dorthin verwendet eBay von der EU-Kommission genehmigte
        Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln
        verpflichten eBay, das EU-Datenschutzniveau bei der Verarbeitung
        relevanter Daten auch außerhalb der EU zu wahren.
      </p>
      <p>
        Diese Klauseln basieren auf einem Durchführungsbeschluss der
        EU-Kommission. Sie finden den Beschluss sowie die Klauseln u.a. hier:{" "}
        <Link to="https://germany.representation.ec.europa.eu/index_de">
          https://germany.representation.ec.europa.eu/index_de
        </Link>{" "}
      </p>
      <p>
        Mehr über die Daten, die durch die Verwendung von eBay verarbeitet
        werden, erfahren Sie in der Datenschutzerklärung auf{" "}
        <Link to="https://www.ebay.com/help/policies/member-behaviour-policies/user-privacy-notice-privacy-policy?id=4260">
          https://www.ebay.com/help/policies/member-behaviour-policies/user-privacy-notice-privacy-policy?id=4260
        </Link>{" "}
        .
      </p>
      <h2 className="text-2xl mt-4">TrustPilot</h2>
      <p>
        Für die Bewertung unserer Dienstleistungen arbeiten wir mit TrustPilot
        zusammen. Die Weitergabe Ihrer Angaben erfolgt nur zum Zwecke des
        Einholens von Bewertungen. Das Bewerten ist freiwillig.
      </p>
      <p>
        TrustPilot ist ein Dienst von Trustpilot A/S mit Sitz in Kopenhagen,
        Dänemark. Auftragsdaten­verarbeitungs­vertrag (Data processing
        agreement). Weitere Informationen finden Sie inder Datenschutzerklärung
        von Familiara:{" "}
        <Link
          to="https://de.legal.trustpilot.com/for-reviewers/end-user-privacy-terms"
          target="_blank"
          rel="follow noopener"
        >
          https://de.legal.trustpilot.com/for-reviewers/end-user-privacy-terms
        </Link>
      </p>
      <h2 className="text-2xl mt-4" id="audio-video-einleitung">
        Audio & Video Einleitung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>
                Audio & Video Datenschutzerklärung Zusammenfassung
              </strong>
              <br />
              👥 Betroffene: Besucher der Website
              <br />
              🤝 Zweck: Optimierung unserer Serviceleistung
              <br />
              📓 Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum
              Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse
              können gespeichert werden.
              <br />
              Mehr Details dazu finden Sie weiter unten in den entsprechenden
              Datenschutztexten.
              <br />
              📅 Speicherdauer: Daten bleiben grundsätzlich gespeichert, solange
              sie für den Dienstzweck nötig sind
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was sind Audio- und Videoelemente?</h2>
      <p>
        Wir haben auf unsere Website Audio- bzw. Videoelemente eingebunden,
        damit Sie sich direkt über unsere Website etwa Videos ansehen oder
        Musik/Podcasts anhören können. Die Inhalte werden von Dienstanbietern
        zur Verfügung gestellt. Alle Inhalte werden also auch von den
        entsprechenden Servern der Anbieter bezogen.
      </p>
      <p>
        Es handelt sich dabei um eingebundene Funktionselemente von Plattformen
        wie etwa YouTube, Vimeo oder Spotify. Die Nutzung dieser Portale ist in
        der Regel kostenlos, es können aber auch kostenpflichtige Inhalte
        veröffentlicht werden. Mit Hilfe dieser eingebundenen Elemente könne Sie
        sich über unsere Website die jeweiligen Inhalte anhören oder ansehen.
      </p>
      <p>
        Wenn Sie Audio- oder Videoelemente auf unsere Website verwenden, können
        auch personenbezogene Daten von Ihnen an die Dienstanbieter übermittelt,
        verarbeitet und gespeichert werden.
      </p>
      <h2 className="text-2xl mt-4">
        Warum verwenden wir Audio- & Videoelemente auf unserer Website?
      </h2>
      <p>
        Natürlich wollen wir Ihnen auf unserer Website das beste Angebot
        liefern. Und uns ist bewusst, dass Inhalte nicht mehr bloß in Text und
        statischem Bild vermittelt werden. Statt Ihnen einfach nur einen Link zu
        einem Video zu geben, bieten wir Ihnen direkt auf unserer Website Audio-
        und Videoformate, die unterhaltend oder informativ und im Idealfall
        sogar beides sind. Das erweitert unser Service und erleichtert Ihnen den
        Zugang zu interessanten Inhalten. Somit bieten wir neben unseren Texten
        und Bildern auch Video und/oder Audio-Inhalte an.
      </p>
      <h2 className="text-2xl mt-4">
        Welche Daten werden durch Audio- & Videoelemente gespeichert?
      </h2>
      <p>
        Wenn Sie eine Seite auf unserer Website aufrufen, die beispielsweise ein
        eingebettetes Video hat, verbindet sich Ihr Server mit dem Server des
        Dienstanbieters. Dabei werden auch Daten von Ihnen an den Drittanbieter
        übertragen und dort gespeichert. Manche Daten werden ganz unabhängig
        davon, ob Sie bei dem Drittanbieter ein Konto haben oder nicht,
        gesammelt und gespeichert. Dazu zählen meist Ihre IP-Adresse,
        Browsertyp, Betriebssystem, und weitere allgemeine Informationen zu
        Ihrem Endgerät. Weiters werden von den meisten Anbietern auch
        Informationen über Ihre Webaktivität eingeholt. Dazu zählen etwa
        Sitzungsdauer, Absprungrate, auf welchen Button Sie geklickt haben oder
        über welche Website Sie den Dienst nutzen. All diese Informationen
        werden meist über Cookies oder Pixel-Tags (auch Web Beacon genannt)
        gespeichert. Pseudonymisierte Daten werden meist in Cookies in Ihrem
        Browser gespeichert. Welche Daten genau gespeichert und verarbeitet
        werden, erfahren Sie stets in der Datenschutzerklärung des jeweiligen
        Anbieters.
      </p>
      <h2 className="text-2xl mt-4">Dauer der Datenverarbeitung</h2>
      <p>
        Wie lange die Daten auf den Servern der Drittanbieter genau gespeichert
        werden, erfahren Sie entweder weiter unten im Datenschutztext des
        jeweiligen Tools oder in der Datenschutzerklärung des Anbieters.
        Grundsätzlich werden personenbezogene Daten immer nur so lange
        verarbeitet, wie es für die Bereitstellung unserer Dienstleistungen oder
        Produkte unbedingt nötig wird. Dies gilt in der Regel auch für
        Drittanbieter. Meist können Sie davon ausgehen, dass gewisse Daten über
        mehrere Jahre auf den Servern der Drittanbieter gespeichert werden.
        Daten können speziell in Cookies unterschiedlich lange gespeichert
        werden. Manche Cookies werden bereits nach dem Verlassen der Website
        wieder gelöscht, anderen können über einige Jahre in Ihrem Browser
        gespeichert sein.
      </p>
      <h2 className="text-2xl mt-4">Widerspruchsrecht</h2>
      <p>
        Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung
        zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das
        funktioniert entweder über unser Cookie-Management-Tool oder über andere
        Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch
        Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten,
        deaktivieren oder löschen. Die Rechtmäßigkeit der Verarbeitung bis zum
        Widerruf bleibt unberührt.
      </p>
      <p>
        Da durch die eingebundenen Audio- und Video-Funktionen auf unserer Seite
        meist auch Cookies verwendet werden, sollte Sie sich auch unsere
        allgemeine Datenschutzerklärung über Cookies durchlesen. In den
        Datenschutzerklärungen der jeweiligen Drittanbieter erfahren Sie
        genaueres über den Umgang und die Speicherung Ihrer Daten.
      </p>
      <h2 className="text-2xl mt-4">
        <strong>Rechtsgrundlage</strong>
      </h2>
      <p>
        Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
        Audio- und Video-Elemente verarbeitet und gespeichert werden können,
        gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung
        <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre
        Daten auch auf Grundlage unseres berechtigten Interesses
        <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und
        guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern
        gespeichert und verarbeitet. Wir setzen die eingebundenen Audio- und
        Video-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung erteilt
        haben.
      </p>
      <h2 className="text-2xl mt-4" id="youtube-datenschutzerklaerung">
        YouTube Datenschutzerklärung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>YouTube Datenschutzerklärung Zusammenfassung</strong>
              <br />
              👥 Betroffene: Besucher der Website
              <br />
              🤝 Zweck: Optimierung unserer Serviceleistung
              <br />
              📓 Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum
              Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse
              können gespeichert werden.
              <br />
              Mehr Details dazu finden Sie weiter unten in dieser
              Datenschutzerklärung.
              <br />
              📅 Speicherdauer: Daten bleiben grundsätzlich gespeichert, solange
              sie für den Dienstzweck nötig sind
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was ist YouTube?</h2>
      <p>
        Wir haben auf unserer Website YouTube-Videos eingebaut. So können wir
        Ihnen interessante Videos direkt auf unserer Seite präsentieren. YouTube
        ist ein Videoportal, das seit 2006 eine Tochterfirma von Google ist.
        Betrieben wird das Videoportal durch YouTube, LLC, 901 Cherry Ave., San
        Bruno, CA 94066, USA. Wenn Sie auf unserer Website eine Seite aufrufen,
        die ein YouTube-Video eingebettet hat, verbindet sich Ihr Browser
        automatisch mit den Servern von YouTube bzw. Google. Dabei werden (je
        nach Einstellungen) verschiedene Daten übertragen. Für die gesamte
        Datenverarbeitung im europäischen Raum ist Google Ireland Limited
        (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich.
      </p>
      <p>
        Im Folgenden wollen wir Ihnen genauer erklären, welche Daten verarbeitet
        werden, warum wir YouTube-Videos eingebunden haben und wie Sie Ihre
        Daten verwalten oder löschen können.
      </p>
      <p>
        Auf YouTube können die User kostenlos Videos ansehen, bewerten,
        kommentieren und selbst hochladen. Über die letzten Jahre wurde YouTube
        zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit wir Videos
        auf unserer Webseite anzeigen können, stellt YouTube einen
        Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut haben.
      </p>
      <h2 className="text-2xl mt-4">
        Warum verwenden wir YouTube-Videos auf unserer Website?
      </h2>
      <p>
        YouTube ist die Videoplattform mit den meisten Besuchern und dem besten
        Content. Wir sind bemüht, Ihnen die bestmögliche User-Erfahrung auf
        unserer Webseite zu bieten. Und natürlich dürfen interessante Videos
        dabei nicht fehlen. Mithilfe unserer eingebetteten Videos stellen wir
        Ihnen neben unseren Texten und Bildern weiteren hilfreichen Content zur
        Verfügung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch
        die eingebetteten Videos leichter gefunden. Auch wenn wir über Google
        Ads Werbeanzeigen schalten, kann Google – dank der gesammelten Daten –
        diese Anzeigen wirklich nur Menschen zeigen, die sich für unsere
        Angebote interessieren.
      </p>
      <h2 className="text-2xl mt-4">
        Welche Daten werden von YouTube gespeichert?
      </h2>
      <p>
        Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video eingebaut
        hat, setzt YouTube zumindest ein Cookie, das Ihre IP-Adresse und unsere
        URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann
        YouTube Ihre Interaktionen auf unserer Webseite meist mithilfe von
        Cookies Ihrem Profil zuordnen. Dazu zählen Daten wie Sitzungsdauer,
        Absprungrate, ungefährer Standort, technische Informationen wie
        Browsertyp, Bildschirmauflösung oder Ihr Internetanbieter. Weitere Daten
        können Kontaktdaten, etwaige Bewertungen, das Teilen von Inhalten über
        Social Media oder das Zufügen zu Ihren Favoriten auf YouTube sein.
      </p>
      <p>
        Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto angemeldet
        sind, speichert Google Daten mit einer eindeutigen Kennung, die mit
        Ihrem Gerät, Browser oder App verknüpft sind. So bleibt beispielsweise
        Ihre bevorzugte Spracheinstellung beibehalten. Aber viele
        Interaktionsdaten können nicht gespeichert werden, da weniger Cookies
        gesetzt werden.
      </p>
      <p>
        In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser
        gesetzt wurden. Wir zeigen einerseits Cookies, die ohne angemeldetes
        YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit
        angemeldetem Account gesetzt werden. Die Liste kann keinen
        Vollständigkeitsanspruch erheben, weil die Userdaten immer von den
        Interaktionen auf YouTube abhängen.
      </p>
      <p>
        <strong>Name:</strong> YSC
        <br />
        <strong>Wert:</strong> b9-CV6ojI5Y312394123-1
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie registriert eine
        eindeutige ID, um Statistiken des gesehenen Videos zu speichern.
        <br />
        <strong>Ablaufdatum:</strong> nach Sitzungsende
      </p>
      <p>
        <strong>Name:</strong> PREF
        <br />
        <strong>Wert:</strong> f1=50000000
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls
        Ihre eindeutige ID. Google bekommt über PREF Statistiken, wie Sie
        YouTube-Videos auf unserer Webseite verwenden.
        <br />
        <strong>Ablaufdatum:</strong> nach 8 Monaten
      </p>
      <p>
        <strong>Name:</strong> GPS
        <br />
        <strong>Wert:</strong> 1<br />
        <strong>Verwendungszweck:</strong> Dieses Cookie registriert Ihre
        eindeutige ID auf mobilen Geräten, um den GPS-Standort zu tracken.
        <br />
        <strong>Ablaufdatum:</strong> nach 30 Minuten
      </p>
      <p>
        <strong>Name:</strong> VISITOR_INFO1_LIVE
        <br />
        <strong>Wert:</strong> 95Chz8bagyU
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite
        des Users auf unseren Webseiten (mit eingebautem YouTube-Video) zu
        schätzen.
        <br />
        <strong>Ablaufdatum:</strong> nach 8 Monaten
      </p>
      <p>
        Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto
        angemeldet sind:
      </p>
      <p>
        <strong>Name:</strong> APISID
        <br />
        <strong>Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7312394123-
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein
        Profil über Ihre Interessen zu erstellen. Genützt werden die Daten für
        personalisierte Werbeanzeigen.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> CONSENT
        <br />
        <strong>Wert:</strong> YES+AT.de+20150628-20-0
        <br />
        <strong>Verwendungszweck:</strong> Das Cookie speichert den Status der
        Zustimmung eines Users zur Nutzung unterschiedlicher Services von
        Google. CONSENT dient auch der Sicherheit, um User zu überprüfen und
        Userdaten vor unbefugten Angriffen zu schützen.
        <br />
        <strong>Ablaufdatum: </strong>nach 19 Jahren
      </p>
      <p>
        <strong>Name:</strong> HSID
        <br />
        <strong>Wert:</strong> AcRwpgUik9Dveht0I
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein
        Profil über Ihre Interessen zu erstellen. Diese Daten helfen
        personalisierte Werbung anzeigen zu können.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> LOGIN_INFO
        <br />
        <strong>Wert:</strong> AFmmF2swRQIhALl6aL…
        <br />
        <strong>Verwendungszweck:</strong> In diesem Cookie werden Informationen
        über Ihre Login-Daten gespeichert.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> SAPISID
        <br />
        <strong>Wert:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie funktioniert, indem es
        Ihren Browser und Ihr Gerät eindeutig identifiziert. Es wird verwendet,
        um ein Profil über Ihre Interessen zu erstellen.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> SID
        <br />
        <strong>Wert:</strong> oQfNKjAsI312394123-
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie speichert Ihre
        Google-Konto-ID und Ihren letzten Anmeldezeitpunkt in digital signierter
        und verschlüsselter Form.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> SIDCC
        <br />
        <strong>Wert:</strong> AN0-TYuqub2JOcDTyL
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie speichert
        Informationen, wie Sie die Webseite nutzen und welche Werbung Sie vor
        dem Besuch auf unserer Seite möglicherweise gesehen haben.
        <br />
        <strong>Ablaufdatum:</strong> nach 3 Monaten
      </p>
      <h2 className="text-2xl mt-4">
        Wie lange und wo werden die Daten gespeichert?
      </h2>
      <p>
        Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den
        Google-Servern gespeichert. Die meisten dieser Server befinden sich in
        Amerika. Unter{" "}
        <Link
          to="https://www.google.com/about/datacenters/locations/?hl=de"
          target="_blank"
          rel="follow noopener"
        >
          https://www.google.com/about/datacenters/locations/?hl=de
        </Link>{" "}
        sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten
        sind auf den Servern verteilt. So sind die Daten schneller abrufbar und
        vor Manipulation besser geschützt.
      </p>
      <p>
        Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten
        können Sie jederzeit löschen, andere werden automatisch nach einer
        begrenzten Zeit gelöscht und wieder andere werden von Google über
        längere Zeit gespeichert. Einige Daten (wie Elemente aus „Meine
        Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem Google-Konto
        gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen.
        Auch wenn Sie nicht in einem Google-Konto angemeldet sind, können Sie
        einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind,
        löschen.
      </p>
      <h2 className="text-2xl mt-4">
        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
      </h2>
      <p>
        Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit der
        2019 eingeführten automatischen Löschfunktion von Standort- und
        Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung –
        entweder 3 oder 18 Monate gespeichert und dann gelöscht.
      </p>
      <p>
        Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren
        Browser so konfigurieren, dass Cookies von Google gelöscht bzw.
        deaktiviert werden. Je nachdem welchen Browser Sie verwenden,
        funktioniert dies auf unterschiedliche Art und Weise. Unter dem
        Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den
        jeweiligen Anleitungen der bekanntesten Browser.
      </p>
      <p>
        Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
        Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
        gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
        entscheiden, ob Sie es erlauben oder nicht.
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
        YouTube-Elemente verarbeitet und gespeichert werden können, gilt diese
        Einwilligung als Rechtsgrundlage der Datenverarbeitung
        <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre
        Daten auch auf Grundlage unseres berechtigten Interesses
        <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und
        guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern
        gespeichert und verarbeitet. Wir setzen die eingebundenen
        YouTube-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung
        erteilt haben. YouTube setzt auch Cookies in Ihrem Browser, um Daten zu
        speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über
        Cookies genau durchzulesen und die Datenschutzerklärung oder sehen Sie
        sich die Cookie-Richtlinien des jeweiligen Dienstanbieters an.
      </p>
      <p>
        YouTube verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin,
        dass nach Meinung des Europäischen Gerichtshofs derzeit kein
        angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies
        kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der
        Datenverarbeitung einhergehen.
      </p>
      <p>
        Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
        Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein,
        Norwegen, also insbesondere in den USA) oder einer Datenweitergabe
        dorthin verwendet YouTube von der EU-Kommission genehmigte
        Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln
        verpflichten YouTube, das EU-Datenschutzniveau bei der Verarbeitung
        relevanter Daten auch außerhalb der EU zu wahren. Diese Klauseln
        basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden
        den Beschluss sowie die Klauseln u.a. hier:{" "}
        <Link
          to="https://germany.representation.ec.europa.eu/index_de"
          target="_blank"
          rel="follow noopener"
        >
          https://germany.representation.ec.europa.eu/index_de
        </Link>{" "}
        .
      </p>
      <p>
        Da YouTube ein Tochterunternehmen von Google ist, gibt es eine
        gemeinsame Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren
        Daten erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung
        unter{" "}
        <Link
          to="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de.
        </Link>{" "}
      </p>
      <h2 className="text-2xl mt-4" id="webdesign-einleitung">
        Webdesign Einleitung
      </h2>
      <table border={1} cellPadding={15}>
        <tbody>
          <tr>
            <td>
              <strong>Webdesign Datenschutzerklärung Zusammenfassung</strong>
              <br />
              👥 Betroffene: Besucher der Website
              <br />
              🤝 Zweck: Verbesserung der Nutzererfahrung
              <br />
              📓 Verarbeitete Daten: Welche Daten verarbeitet werden, hängt
              stark von den verwendeten Diensten ab. Meist handelt es sich etwa
              um IP-Adresse, technische Daten, Spracheinstellungen,
              Browserversion, Bildschirmauflösung und Name des Browsers. Mehr
              Details dazu finden Sie bei den jeweils eingesetzten
              Webdesign-Tools.
              <br />
              📅 Speicherdauer: abhängig von den eingesetzten Tools
              <br />
              ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung),
              Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-2xl mt-4">Was ist Webdesign?</h2>
      <p>
        Wir verwenden auf unserer Website verschiedene Tools, die unserem
        Webdesign dienen. Bei Webdesign geht es nicht, wie oft angenommen, nur
        darum, dass unsere Website hübsch aussieht, sondern auch um
        Funktionalität und Leistung. Aber natürlich ist die passende Optik einer
        Website auch eines der großen Ziele professionellen Webdesigns.
        Webdesign ist ein Teilbereich des Mediendesigns und beschäftigt sich
        sowohl mit der visuellen als auch der strukturellen und funktionalen
        Gestaltung einer Website. Ziel ist es mit Hilfe von Webdesign Ihre
        Erfahrung auf unserer Website zu verbessern. Im Webdesign-Jargon spricht
        man in diesem Zusammenhang von User-Experience (UX) und Usability. Unter
        User Experience versteht man alle Eindrücke und Erlebnisse, die der
        Websitebesucher auf einer Website erfährt. Ein Unterpunkt der User
        Experience ist die Usability. Dabei geht es um die Nutzerfreundlichkeit
        einer Website. Wert gelegt wird hier vor allem darauf, dass Inhalte,
        Unterseiten oder Produkte klar strukturiert sind und Sie leicht und
        schnell finden, wonach Sie suchen. Um Ihnen die bestmögliche Erfahrung
        auf unserer Website zu bieten, verwenden wir auch sogenannte
        Webdesign-Tools von Drittanbietern. Unter die Kategorie „Webdesign“
        fallen in dieser Datenschutzerklärung also alle Dienste, die unsere
        Website gestalterisch verbessern. Das können beispielsweise
        Schriftarten, diverse Plugins oder andere eingebundene
        Webdesign-Funktionen sein.
      </p>
      <h2 className="text-2xl mt-4">Warum verwenden wir Webdesign-Tools?</h2>
      <p>
        Wie Sie Informationen auf einer Website aufnehmen, hängt sehr stark von
        der Struktur, der Funktionalität und der visuellen Wahrnehmung der
        Website ab. Daher wurde auch für uns ein gutes und professionelles
        Webdesign immer wichtiger. Wir arbeiten ständig an der Verbesserung
        unserer Website und sehen dies auch als erweiterte Dienstleistung für
        Sie als Websitebesucher. Weiters hat eine schöne und funktionierende
        Website auch wirtschaftliche Vorteile für uns. Schließlich werden Sie
        uns nur besuchen und unsere Angebote in Anspruch nehmen, wenn Sie sich
        rundum wohl fühlen.
      </p>
      <h2 className="text-2xl mt-4">
        Welche Daten werden durch Webdesign-Tools gespeichert?
      </h2>
      <p>
        Wenn Sie unsere Website besuchen, können Webdesign-Elemente in unseren
        Seiten eingebunden sein, die auch Daten verarbeiten können. Um welche
        Daten es sich genau handelt, hängt natürlich stark von den verwendeten
        Tools ab. Weiter unter sehen Sie genau, welche Tools wir für unsere
        Website verwenden. Wir empfehlen Ihnen für nähere Informationen über die
        Datenverarbeitung auch die jeweilige Datenschutzerklärung der
        verwendeten Tools durchzulesen. Meistens erfahren Sie dort, welche Daten
        verarbeitet werden, ob Cookies eingesetzt werden und wie lange die Daten
        aufbewahrt werden. Durch Schriftarten wie etwa Google Fonts werden
        beispielsweise auch Informationen wie Spracheinstellungen, IP-Adresse,
        Version des Browsers, Bildschirmauflösung des Browsers und Name des
        Browsers automatisch an die Google-Server übertragen.
      </p>
      <h2 className="text-2xl mt-4">Dauer der Datenverarbeitung</h2>
      <p>
        Wie lange Daten verarbeitet werden, ist sehr individuell und hängt von
        den eingesetzten Webdesign-Elementen ab. Wenn Cookies beispielsweise zum
        Einsatz kommen, kann die Aufbewahrungsdauer nur eine Minute, aber auch
        ein paar Jahre dauern. Machen Sie sich diesbezüglich bitte schlau. Dazu
        empfehlen wir Ihnen einerseits unseren allgemeinen Textabschnitt über
        Cookies sowie die Datenschutzerklärungen der eingesetzten Tools. Dort
        erfahren Sie in der Regel, welche Cookies genau eingesetzt werden, und
        welche Informationen darin gespeichert werden. Google-Font-Dateien
        werden zum Beispiel ein Jahr gespeichert. Damit soll die Ladezeit einer
        Website verbessert werden. Grundsätzlich werden Daten immer nur so lange
        aufbewahrt, wie es für die Bereitstellung des Dienstes nötig ist. Bei
        gesetzlichen Vorschreibungen können Daten auch länger gespeichert
        werden.
      </p>
      <h2 className="text-2xl mt-4">Widerspruchsrecht</h2>
      <p>
        Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung
        zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das
        funktioniert entweder über unser Cookie-Management-Tool oder über andere
        Opt-Out-Funktionen. Sie können auch die Datenerfassung durch Cookies
        verhindern, indem Sie in Ihrem Browser die Cookies verwalten,
        deaktivieren oder löschen. Unter Webdesign-Elementen (meistens bei
        Schriftarten) gibt es allerdings auch Daten, die nicht ganz so einfach
        gelöscht werden können. Das ist dann der Fall, wenn Daten direkt bei
        einem Seitenaufruf automatisch erhoben und an einen Drittanbieter (wie
        z. B. Google) übermittelt werden. Wenden Sie sich dann bitte an den
        Support des entsprechenden Anbieters. Im Fall von Google erreichen Sie
        den Support unter{" "}
        <Link to="https://support.google.com/?hl=de">
          https://support.google.com/?hl=de
        </Link>{" "}
        .
      </p>
      <h2 className="text-2xl mt-4">Rechtsgrundlage</h2>
      <p>
        Wenn Sie eingewilligt haben, dass Webdesign-Tools eingesetzt werden
        dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung
        diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a
        DSGVO (Einwilligung) die Rechtsgrundlage für die Verarbeitung
        personenbezogener Daten, wie sie bei der Erfassung durch Webdesign-Tools
        vorkommen kann, dar. Von unserer Seite besteht zudem ein berechtigtes
        Interesse, das Webdesign auf unserer Website zu verbessern. Schließlich
        können wir Ihnen nur dann ein schönes und professionelles Webangebot
        liefern. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit.
        f DSGVO (Berechtigte Interessen). Wir setzen Webdesign-Tools gleichwohl
        nur ein, soweit Sie eine Einwilligung erteilt haben. Das wollen wir hier
        auf jeden Fall nochmals betonen.
      </p>

      <h2 className="text-2xl mt-4">Zahlungsdienstleister</h2>
      <p>
        Wir nutzen externe Zahlungsdienstleister, um Dienstleistungen
        abzurechnen oder damit Nutzende freiwillige Beiträge zahlen können.
      </p>

      <h2 className="text-2xl mt-4">PayPal</h2>
      <p>
        Auf dieser Internetseite und in E-Mails sind Komponenten von PayPal
        integriert. Dies entspricht unserem berechtigten Interesse, eine
        effiziente und sichere Zahlungsmethode anzubieten (Art. 6 Abs. 1 lit. f
        DSGVO). Zahlungen werden über sogenannte virtuelle PayPal-Konten oder
        Kreditkarten und Girokonten abgewickelt, auch wenn kein PayPal-Konto
        besteht. Wenn Nutzer im Bestellvorgang oder aufgrund eines Hinweises
        „PayPal" als Zahlungsdienstleister auswählt, werden automatisiert Daten
        der betroffenen Person an PayPal übermittelt. Mit der Auswahl dieser
        Zahlungsoption willigt die betroffene Person in die zur
        Zahlungsabwicklung erforderliche Übermittlung personenbezogener Daten
        ein. Dies sind in der Regel Vorname, Nachname, Adresse, E-Mail-Adresse,
        Telefonnummer oder andere Daten, die zur Zahlungsabwicklung notwendig
        sind. Alle Daten werden lediglich zwecks Zahlungsabwicklung und zur
        Betrugsprävention übermittelt. Die Übermittlung der Daten bezweckt die
        Zahlungsabwicklung und die Betrugsprävention. PayPal kann zwecks
        Bonitätsprüfung Ihre Daten (insb. Name, Adresse, Geburtsdatum und
        Bankkontodetails) ans Auskunftsdateien weitergeben. PayPal ist ein
        Dienst von PayPal (Europe) S.à.r.l. & Cie. S.C.A. mit Sitz in
        Luxembourg, Luxembourg. Weitere Informationen zum Datenschutz und
        Widerspruchs- und Beseitigungsmöglichkeiten gegenüber PayPal finden Sie
        in der Datenschutzerklärung von PayPal:
        https://www.paypal.com/de/webapps/mpp/ua/privacy-full
      </p>

      <p>Alle Texte sind urheberrechtlich geschützt.</p>
      <p style={{ marginTop: "15px" }}>
        Quelle: Erstellt mit dem{" "}
        <Link
          to="https://www.adsimple.de/datenschutz-generator/"
          title="Datenschutz Generator von AdSimple für Deutschland"
        >
          Datenschutz Generator
        </Link>{" "}
        von AdSimple
      </p>
    </div>
  );
};

export default DataProtection;

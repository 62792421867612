"use client";
import { Database } from "@/app/types/database";
import { createClient } from "@supabase/supabase-js";

// Create a single supabase client for interacting with your database
export let supabase = createClient<Database>(
  process.env.NEXT_PUBLIC_SUPABASE_URL,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
  { auth: { persistSession: true } }
);

global.supabase = supabase;

export const updateToken = (jwt) => {
  supabase = createClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    {
      global: { headers: { Authorization: `Bearer ${jwt}` } },
      auth: { autoRefreshToken: false },
      realtime: {
        headers: {
          apikey: jwt, // my custom token returned from my edge fn --docs have it backwards
        },
        params: {
          apikey: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
        },
      },
    }
  );
  supabase.realtime.setAuth(jwt);
  global.supabase = supabase;
};

export const clearToken = () => {
  supabase = createClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    { auth: { persistSession: false } }
  );
  global.supabase = supabase;
};

import { Link } from "react-router-dom";
import { useContext } from "react";
import { UrlContext } from "../components/UrlProvider";

const BLOG_ARTICLES = [
  {
    title: "Wie man aus einem Video einen Blog-Artikel generiert",
    link: "/articles/wie-man-aus-einem-video-einen-blog-artikel-generiert",
    date: new Date(2024, 6, 1),
    thumbnail: new URL("/public/HowTo.jpg?as=webp&width=300", import.meta.url),
    excerpt:
      "Erfahren Sie, wie Sie einfach und effizient Videos in hochwertige Blog-Artikel umwandeln können...",
  },
];

export const LandingPage = () => {
  const { url, setUrl } = useContext(UrlContext);
  return (
    <>
      {/* Hero Section */}
      <section className="md:h-screen flex flex-col justify-center text-center md:text-left md:flex-row md:justify-between md:items-center lg:px-48 md:px-12 px-4">
        <div className="md:flex-1 md:mr-10">
          <h1 className="font-pt-serif text-4xl font-bold mb-7">
            Der beste Content Manager ist dein
            <span className="bg-underline1 bg-left-bottom bg-no-repeat p-2 bg-100%">
              YouTube-Kanal
            </span>
          </h1>
          <p className="font-pt-serif font-normal mb-7">
            Verwandle jedes Video mit nur einem Klick in einen Artikel oder
            Social Media Beitrag. Unsere AI optimiert den Inhalt für
            Suchmaschinen und organische Reichweite.
          </p>
          <div className="font-montserrat">
            <a href="#start">
              <button className="bg-black px-6 py-4 rounded-lg border-2 border-black border-solid text-white mr-2 mb-2">
                Kostenlos testen
              </button>
            </a>
            <a href="#how">
              <button className="hover:bg-white px-6 py-4 border-2 border-black border-solid rounded-lg">
                Wie geht das?
              </button>
            </a>
          </div>
        </div>
        <div className="flex justify-around md:block mt-8 md:mt-0 md:flex-1">
          <div className="relative md:ml-10">
            <img
              src={require("@/public/Highlight1.svg")}
              alt="highlight_splashes"
              className="absolute -top-16 -left-4"
            />
          </div>
          <img
            src={
              new URL(
                "/public/cleverpenman-transparent.png?as=webp&width=400",
                import.meta.url
              ).href
            }
            alt="Logo"
            className="hidden md:block mx-auto"
          />
          <div className="relative">
            <img
              src={require("@/public/Highlight2.svg")}
              alt="highlight_splashes"
              className="absolute -bottom-10 right-6"
            />
          </div>
        </div>
      </section>

      {/* How it works */}
      <section className="bg-black text-white sectionSize">
        <div id="how">
          <h2 className="secondaryTitle bg-underline2 bg-100%">
            3 Schritte von Video zu Artikel
          </h2>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 mx-8 flex flex-col items-center my-4">
            <div className="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3">
              1
            </div>
            <h3 className="font-montserrat font-medium text-xl mb-2">
              Link eingeben
            </h3>
            <p className="text-center font-montserrat">
              Gebe den Link eines YouTube-Videos (Englisch oder Deutsch) ein. Am
              Besten ist das Ergebnis, wenn das Video 3 bis 20 Minuten lang ist.
            </p>
          </div>
          <div className="flex-1 mx-8 flex flex-col items-center my-4">
            <div className="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3">
              2
            </div>
            <h3 className="font-montserrat font-medium text-xl mb-2">
              Beitrag kopieren
            </h3>
            <p className="text-center font-montserrat">
              Unsere AI generiert einen SEO-Artikel zum Video. Du kannst ihn mit
              einem Klick als Text, HTML oder Markdown kopieren.
            </p>
          </div>
          <div className="flex-1 mx-8 flex flex-col items-center my-4">
            <div className="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3">
              3
            </div>
            <h3 className="font-montserrat font-medium text-xl mb-2">
              Veröffentlichen
            </h3>
            <p className="text-center font-montserrat">
              Den kopierten Text kannst du in WordPress oder dem Tool deiner
              Wahl einfügen. Den Inhalt erhältst du als HTML, Markdown oder
              normalen Text.
            </p>
          </div>
        </div>
      </section>

      {/* Start with Article */}
      <section id="start" className="sectionSize bg-secondary align">
        <h2 className="secondaryTitle bg-underline3 bg-100%">
          Artikel erstellen
        </h2>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium">
            Aus welchem Video möchtest du einen Artikel erstellen?
          </label>
          <input
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            type="text"
            id="url"
            className="border text-sm rounded-lg block w-full p-2.5 border-gray-600 placeholder-gray-400  focus:ring-coral-500 focus:border-coral-500"
            placeholder="https://www.youtube.com/watch?v=TX9qSaGXFyg"
            required
          />
        </div>
        <Link to="/create-transcript">
          <button className="bg-black px-6 py-4 rounded-lg border-2 border-black border-solid text-white mr-2 mb-2">
            Kostenlos testen
          </button>
        </Link>
      </section>

      {/* Features */}
      <section id="features" className="sectionSize bg-secondary">
        <div>
          <h2 className="secondaryTitle bg-underline4 bg-100%">Funktionen</h2>
        </div>
        <div className="md:grid md:grid-cols-2 md:grid-rows-2">
          <div className="flex items-start font-montserrat my-6 mr-10">
            <img
              src={require("@/public/icons/Heart.svg")}
              alt="heart icon"
              className="h-7 mr-4"
            />
            <div>
              <h3 className="font-semibold text-2xl">
                Optimiert für Deutsch & Englisch
              </h3>
              <p>
                Du kannst deutsche und englische Videos nutzen. CleverPenMan.com
                ist optimiert, um die Nuancen der deutschen Sprache abzubilden.
                Mehr als nur Duzen oder Siezen.
              </p>
            </div>
          </div>

          <div className="flex items-start font-montserrat my-6 mr-10">
            <img
              src={require("@/public/icons/Heart.svg")}
              alt="heart icon"
              className="h-7 mr-4"
            />
            <div>
              <h3 className="font-semibold text-2xl">
                Individuell wie deine Inhalte
              </h3>
              <p>
                Nutze die Ausdrucksweise des Videos oder wähle einen von
                CleverPenMans verschiedenen Schreibstilen zur Auswahl. Somit
                passt der Inhalt zu deiner Kommunikationsart.
              </p>
            </div>
          </div>

          <div className="flex items-start font-montserrat my-6 mr-10">
            <img
              src={require("@/public/icons/Heart.svg")}
              alt="heart icon"
              className="h-7 mr-4"
            />
            <div>
              <h3 className="font-semibold text-2xl">Einfaches Exportieren</h3>
              <p>
                Den erstellten Text kannst du einfach exportieren. Per Text,
                HTML oder Markdown kannst du ihn dann in Wordpress, Social Media
                oder jedem beliebigen CMS veröffentlichen.
              </p>
            </div>
          </div>

          <div className="flex items-start font-montserrat my-6 mr-10">
            <img
              src={require("@/public/icons/Heart.svg")}
              alt="heart icon"
              className="h-7 mr-4"
            />
            <div>
              <h3 className="font-semibold text-2xl">Schnell und Effektiv</h3>
              <p>
                Es dauert nur wenige Minuten bis zu einem fertigen Artikel.
                Somit kannst du mehr Content veröffentlichen.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Blog Articles */}
      <section id="blog-articles" className="bg-black text-white sectionSize">
        <div>
          <h2 className="secondaryTitle bg-underline3W p-12 mb-0 bg-center bg-100%">
            Unser Blog
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4">
          {BLOG_ARTICLES.map((article, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg shadow-md overflow-hidden"
            >
              <div className="p-4 text-center">
                <Link to={article.link}>
                  <h3 className="font-montserrat font-bold text-lg mb-2">
                    {article.title}
                  </h3>
                </Link>
                <div className="font-montserrat text-sm font-extralight mb-4">
                  {article.date.toLocaleDateString("de-DE", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </div>
                <p className="font-montserrat text-sm font-extralight mb-4">
                  {article.excerpt}
                </p>
                <Link to={article.link}>
                  <img
                    className="w-full h-48 object-cover rounded-lg"
                    src={article.thumbnail.href}
                    alt={article.title}
                  />
                </Link>
                <Link
                  to={article.link}
                  className="inline-block bg-black px-6 py-2 rounded-lg border-2 border-black border-solid text-white mr-2 mt-2 mb-2"
                >
                  Weiterlesen
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Pricing */}
      <section id="pricing" className="sectionSize bg-secondary !pb-0 pt-2">
        <div>
          <h2 className="secondaryTitle bg-underline3 mb-0 bg-100%">Pricing</h2>
        </div>
        <div className="flex w-full flex-col md:flex-row">
          <div className="flex-1 flex flex-col mx-6 shadow-2xl relative bg-secondary rounded-2xl py-5 px-8 my-8 md:top-24">
            <h3 className="font-pt-serif font-normal text-2xl mb-4">
              10 Artikel
            </h3>
            <div className="font-montserrat font-bold text-2xl mb-4">
              € 0,75<span className="font-normal text-base"> / Artikel</span>
            </div>

            <div className="flex">
              <img
                src={require("@/public/icons/CheckedBox.svg")}
                alt="checkbox"
                className="mr-1"
              />
              <p>Gültig für ein Jahr</p>
            </div>
            <div className="flex">
              <img
                src={require("@/public/icons/CheckedBox.svg")}
                alt=""
                className="mr-1"
              />
              <p>Zukünftigen Features nutzen</p>
            </div>
            <div className="flex">
              <img
                src={require("@/public/icons/CheckedBox.svg")}
                alt="checkbox"
                className="mr-1"
              />
              <p>Support per E-Mail</p>
            </div>
            <a
              className="border-2 border-solid border-black rounded-xl text-lg py-3 mt-4 text-center"
              href="https://buy.cleverpenman.com/b/DMWUY"
            >
              <button>Wählen</button>
            </a>
          </div>

          <div className="flex-1 flex flex-col mx-6 shadow-2xl relative bg-secondary rounded-2xl py-5 px-8 my-8 md:top-12">
            <h3 className="font-pt-serif font-normal text-2xl mb-4">
              100 Artikel
            </h3>
            <div className="font-montserrat font-bold text-2xl mb-4">
              € 0,49
              <span className="font-normal text-base"> / Artikel</span>
            </div>

            <div className="flex">
              <img
                src={require("@/public/icons/CheckedBox.svg")}
                alt=""
                className="mr-1"
              />
              <p>Kein Verfall des Guthabens</p>
            </div>
            <div className="flex">
              <img
                src={require("@/public/icons/CheckedBox.svg")}
                alt=""
                className="mr-1"
              />
              <p>Zukünftige Features nutzen</p>
            </div>
            <div className="flex">
              <img
                src={require("@/public/icons/CheckedBox.svg")}
                alt=""
                className="mr-1"
              />
              <p>E-Mail & Telefon-Support</p>
            </div>
            <a
              className="border-2 border-solid border-black rounded-xl text-lg py-3 mt-4 text-center"
              href="https://buy.cleverpenman.com/b/1iZP9"
            >
              <button>Wählen</button>
            </a>
          </div>

          <div className="flex-1 flex flex-col mx-6 shadow-2xl relative bg-secondary rounded-2xl py-5 px-8 my-8 md:top-24">
            <h3 className="font-pt-serif font-normal text-2xl mb-4">
              Ab 1.000 Artikel
            </h3>
            <div className="font-montserrat font-bold text-2xl mb-4">
              <span className="font-normal text-base"> Auf Anfrage</span>
            </div>

            <div className="flex">
              <p>
                Nimm gerne Kontakt zu uns auf, damit wir die passende Lösung für
                deinen Bedarf finden können.{" "}
              </p>
            </div>
            <a
              className="border-2 border-solid border-black rounded-xl text-lg py-3 mt-4 text-center"
              href="mailto:cleverpenman@dock51.de"
            >
              <button>Kontakt</button>
            </a>
          </div>
        </div>
      </section>

      {/* FAQ */}
      <section
        id="faq"
        className="sectionSize items-start pt-8 md:pt-36 bg-black text-white"
      >
        <div>
          <h2 className="secondaryTitle bg-highlight3 p-12 mb-0 bg-center bg-100%">
            FAQ
          </h2>
        </div>

        <div className="w-full py-4">
          <div className="flex justify-between items-center">
            <div className="font-montserrat font-bold mr-auto">
              Kann ich jedes Video nutzen?
            </div>
          </div>
          <div className="font-montserrat text-sm font-extralight pb-1 ">
            Jedes Video auf YouTube in Deutscher oder Englischer Sprache kann
            genutzt werden. Am besten eignen sich Videos zwischen zwei und 15
            Minuten.
          </div>
        </div>
        <hr className="w-full bg-white" />

        <div className="w-full py-4">
          <div className="flex justify-between items-center">
            <div className="font-montserrat font-bold mr-auto">
              Wofür kann ich den generierten Text nutzen?
            </div>
          </div>
          <div className="font-montserrat text-sm font-extralight pb-1 ">
            Der Anwendungsbereich ist groß. Bisher wird unser Tool vor allem für
            SEO-Blogs und Portale für Mitarbeitende genutzt. Auch das Erstellen
            von (technischen) Dokumentationen ist möglich.
          </div>
        </div>
        <hr className="w-full bg-white" />

        <div className="w-full py-4">
          <div className="flex justify-between items-center">
            <div className="font-montserrat font-bold mr-auto">
              Wie wird die Qualität der Texte sichergestellt?
            </div>
          </div>
          <div className="font-montserrat text-sm font-extralight pb-1 ">
            Wir nutzen die besten Sprachmodelle auf dem Markt und bringen viel
            Erfahrung in Marketing und AI mit. Wir haben durch viele Testläufe
            die Qualität der Ergebnisse stets verbessert. Eine Garantie für die
            Korrektheit der Texte kann allerdings nicht übernommen werden. Wir
            empfehlen daher die generierten Text auf Korrektheit zu prüfen.
          </div>
        </div>
      </section>
    </>
  );
};

import React, { FC } from "react";

type Props<T1, T2> = {
  value: T1 | T2;
  onChange: (v: T1 | T2) => any;
  options: [T1, T2];
  optionLabels?: [string, string];
};

export function Toggle<T1, T2>({
  value,
  onChange,
  options,
  optionLabels,
}: Props<T1, T2>) {
  return (
    <div className="flex space-x-4">
      <button
        className={`${
          value == options[0]
            ? "bg-[00005D] text-white"
            : "bg-white hover:bg-gray-200 hover:text-black"
        } font-bold py-2 px-4 rounded`}
        onClick={() => onChange(options[0])}
      >
        {optionLabels?.[0] ?? options[0].toString()}
      </button>
      <button
        className={`${
          value == options[1]
            ? "bg-[00005D] text-white"
            : "bg-white hover:bg-gray-200 hover:text-black"
        } font-bold py-2 px-4 rounded`}
        onClick={() => onChange(options[1])}
      >
        {optionLabels?.[1] ?? options[1].toString()}
      </button>
    </div>
  );
}

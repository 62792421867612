import React, { createContext, useState } from "react";

export const UrlContext = createContext(null!);

export const UrlProvider = ({ children }) => {
  const [url, setUrl] = useState("");

  return (
    <UrlContext.Provider value={{ url, setUrl }}>
      {children}
    </UrlContext.Provider>
  );
};

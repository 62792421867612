"use client";

import { useState } from "react";

const CopyComp = ({ text }: { text: string; ref }) => {
  const [copyText, setCopyText] = useState("Als Markdown Kopieren");
  const [copyHTMLText, setCopyHTMLText] = useState("Als HTML Kopieren");

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopyText("✅ In Zwischenablage kopiert");

    const timeId = setTimeout(() => {
      setCopyText("Als Markdown Kopieren");
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  };

  const handleHTMLCopy = () => {
    const htmlText = document
      .getElementsByClassName("markdownHolder")
      .item(0).innerHTML;

    navigator.clipboard.writeText(htmlText);
    setCopyHTMLText("✅ In Zwischenablage kopiert");

    const timeId = setTimeout(() => {
      setCopyHTMLText("Als HTML Kopieren");
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          handleCopy();
        }}
        className="w-fit ml-auto border focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center focus:ring-gray-600 bg-gray-800 border-gray-700 text-white hover:bg-gray-700 mr-2 mb-2"
      >
        {copyText}
      </button>
      <button
        type="button"
        onClick={() => {
          handleHTMLCopy();
        }}
        className="w-fit ml-auto border focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center focus:ring-gray-600 bg-gray-800 border-gray-700 text-white hover:bg-gray-700 mr-2 mb-2"
      >
        {copyHTMLText}
      </button>
    </>
  );
};

export default CopyComp;

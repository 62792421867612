import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { supabase } from "../utils/supabase_instance";
import { FunctionsHttpError } from "@supabase/supabase-js";
import { getVoucherUsesLeft } from "../utils/voucherUsesLeft";

export const LoginPage = () => {
  const [voucher, setVoucher] = useState(""); // TODO change to context
  const [trialMail, setTrialMail] = useState("");
  const [trialMailError, setTrialMailError] = useState("");
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const [noUsesLeft, setNoUsesLeft] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [trialMailSent, setTrialMailSent] = useState(false);

  const handleVoucherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVoucher(event.target.value);
  };

  const handleTrialMailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setTrialMail(event.target.value);

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleVoucherSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoading(true);
    try {
      await auth.signIn(voucher);

      const usesLeft = await getVoucherUsesLeft(voucher);

      if (usesLeft > 0) {
        navigate("/create-transcript");
      }
      setNoUsesLeft(true);
    } catch (err) {
      setLoginError(true);
      console.error(err);
    }
    setLoading(false);
  };

  const handleTrialMailSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoading(true);
    setTrialMailError("");
    try {
      const response = await supabase.functions.invoke("sendDemoMail", {
        body: { mail: trialMail },
      });
      setTrialMailSent(true);
      if (response.error instanceof FunctionsHttpError) {
        const { msg } = await response.error.context.json();
        if (msg) {
          throw new Error(msg);
        } else {
          throw new Error("An error occurred while sending the mail");
        }
      }
    } catch (err) {
      setTrialMailSent(true);
      setTrialMailError(err.message);
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <>
      <section className="sectionSize bg-secondary align">
        <h2 className="secondaryTitle bg-underline3 bg-100%">
          Guthaben aufladen
        </h2>
        <h3 className="text-xl">
          Unten gibt es einen kostenlosen Code zum Testen.
        </h3>
        <p className="text-center">
          Du kannst dich mit deinem Code anmelden. Jeder Code hat ein Kontingent
          an Artikeln, die du damit generieren kannst.
        </p>
        <div className="w-screen h-screen flex justify-center">
          {!loading ? (
            <div className="w-full flex items-center flex-col max-w-[900px] gap-[16px] mt-[32px]">
              <form
                onSubmit={handleVoucherSubmit}
                className="w-full max-w-[400px] cursor-pointer bg-secondary rounded-md shadow-2xl p-[16px] flex flex-col gap-[16px] animate-fadeIn"
              >
                <label htmlFor="voucher" className="text-[16px] font-medium">
                  Melde dich mit deinem Code an:
                </label>
                <input
                  type="text"
                  id="voucher"
                  name="voucher"
                  placeholder="a42aa4a2-42aa-42aa-4a2a-4a24aa24242a"
                  value={voucher}
                  onChange={handleVoucherChange}
                  className="border border-gray-400 rounded-md p-[8px] text-[16px] font-medium"
                />
                <button
                  type="submit"
                  className="bg-black text-white rounded-md p-[8px] text-[16px] font-medium hover:bg-[00005D] transition-colors"
                >
                  Weiter
                </button>
              </form>
              {noUsesLeft && (
                <div className="w-full flex items-center flex-col max-w-[900px]">
                  <div className="error mb-4 text-center text-white text-lg font-bold w-full max-w-[400px] rounded-md shadow-2xl p-4 flex flex-col">
                    <p>Dein Guthaben ist leider aufgebraucht.</p>
                  </div>
                </div>
              )}{" "}
              {loginError && (
                <div className="w-full flex items-center flex-col max-w-[900px]">
                  <div className="error mb-4 text-center text-white text-lg font-bold w-full max-w-[400px] rounded-md shadow-2xl p-4 flex flex-col">
                    <p>Dieser Code ist ungültig.</p>
                  </div>
                </div>
              )}
              <form
                onSubmit={handleTrialMailSubmit}
                className="w-full max-w-[400px] cursor-pointer bg-secondary rounded-md shadow-2xl p-[16px] flex flex-col gap-[16px] animate-fadeIn"
              >
                {!trialMailSent ? (
                  <>
                    <label
                      htmlFor="voucher"
                      className="text-[16px] font-medium"
                    >
                      Du hast keinen Code mit Guthaben?
                      <br />
                      <small>
                        Lasse dir einen mit kostenlosem Guthaben zuschicken.
                        Damit kannst du das Tool kostenlos testen.
                      </small>
                    </label>
                    <input
                      type="text"
                      id="trialMail"
                      name="trialMail"
                      inputMode="email"
                      placeholder="mail@mail.com"
                      value={trialMail}
                      onChange={handleTrialMailChange}
                      className="border border-gray-400 rounded-md p-[8px] text-[16px] font-medium"
                    />
                    <button
                      type="submit"
                      className="bg-black text-white rounded-md p-[8px] text-[16px] font-medium hover:bg-[00005D] transition-colors"
                    >
                      Senden
                    </button>
                  </>
                ) : trialMailError ? (
                  <div className=" mb-4 text-center text-lg font-bold w-full max-w-[400px] p-4 flex flex-col">
                    <p>{trialMailError}</p>
                    <button
                      onClick={() => {
                        setTrialMailSent(false);
                        setTrialMailError("");
                      }}
                      className="bg-black text-white rounded-md p-[8px] text-[16px] font-medium hover:bg-[00005D] transition-colors"
                    >
                      Zurück
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="mb-4 text-center  text-lg font-bold w-full max-w-[400px] p-4 flex flex-col">
                      <p>Dein Code wurde an {trialMail} gesendet.</p>
                    </div>
                  </>
                )}
              </form>
            </div>
          ) : (
            <div className="w-full flex items-center flex-col max-w-[900px] gap-[16px] mt-[32px]">
              <div className="w-full flex items-center flex-col max-w-[900px]">
                <div className=" mb-4 text-center  text-lg font-bold w-full max-w-[400px] rounded-md shadow-2xl p-4 flex flex-col">
                  <p>Lade...</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

import React from "react";
import { Link } from "react-router-dom";

export function NotFoundPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center bg-gray-100 text-gray-800">
      <h1 className="text-3xl font-bold mb-4">
        Oh, da ist etwas schief gelaufen!
      </h1>
      <div className="text-lg">
        <p>Diese Seite existiert leider nicht.</p>
        <Link to="/" className="text-blue-500 hover:underline">
          Zurück auf Start
        </Link>{" "}
      </div>
    </div>
  );
}

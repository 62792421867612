import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/AuthProvider";
import { ConfigProvider } from "./components/ConfigProvider";
import { UrlProvider } from "./components/UrlProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ConfigProvider>
        <BrowserRouter>
          <UrlProvider>
            <App />
          </UrlProvider>
        </BrowserRouter>
      </ConfigProvider>
    </AuthProvider>
  </React.StrictMode>
);

import { useState } from "react";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const primaryItems = [
    { name: "3 Schritte von Video zu Artikel", href: "/#how" },
    { name: "Funktionen", href: "/#features" },
    { name: "Preise", href: "/#pricing" },
  ];

  const secondaryItems = {
    // signin: { name: "Guthaben aufladen", href: "/login" },
    signup: { name: "Artikel erstellen", href: "/create-transcript" },
  };

  return (
    <nav className="bg-white shadow-lg mb-12 md:mb-0">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between">
          <div className="flex space-x-7">
            <div>
              <Link to="/" className="flex items-center py-4 px-2">
                <img
                  src={
                    new URL(
                      "/public/cleverpenman-transparent.png?as=webp&width=400",
                      import.meta.url
                    ).href
                  }
                  alt="Logo"
                  className="h-14 w-14 mr-2"
                />
                <span className="font-semibold text-lg">CleverPenMan.com</span>
              </Link>
            </div>
            <div className="hidden md:flex items-center space-x-8">
              {primaryItems.map((item) => (
                <a
                  key={item.name}
                  className="py-4 px-2 text-gray-500 font-semibold hover:text-gray-900 transition duration-300"
                  href={item.href}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          {/* Secondary Navbar items */}
          <div className="hidden md:flex items-center space-x-3">
            {/* <Link
                to={secondaryItems.signin.href}
                className="font-montserrat hover:bg-white px-6 py-2 border-2 border-black border-solid rounded-lg"
              >
                {secondaryItems.signin.name}
              </Link> */}

            <Link
              to={secondaryItems.signup.href}
              className="font-montserrat bg-black px-6 py-2 rounded-lg border-2 border-black border-solid text-white "
            >
              {secondaryItems.signup.name}
            </Link>
          </div>
          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              className="outline-none mobile-menu-button"
              onClick={toggleMenu}
            >
              <svg
                className="w-6 h-6 text-gray-500 hover:text-blue-500"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div className={`mobile-menu ${menuOpen ? "" : "hidden"}`}>
        <div className="px-4 pb-4">
          <ul className="list-none">
            {primaryItems.map((item) => (
              <li key={item.name}>
                <a
                  className="block text-sm px-2 py-4 hover:bg-blue-500 transition duration-300"
                  href={item.href}
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
          <div className="flex flex-col items-center space-y-2">
            <Link
              to={secondaryItems.signup.href}
              className="font-montserrat bg-black px-6 py-2 rounded-lg border-2 border-black border-solid text-white "
            >
              {secondaryItems.signup.name}
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

import { useContext, useState } from "react";

import { createContent } from "@/app/utils/createContent";
import { createTranscript } from "@/app/utils/createTranscript";
import { Variant } from "../types/enums";
import { YoutubeVideoComponent } from "../components/YoutubeVideoComponent";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../hooks/useConfig";
import { AuthStatus } from "../components/AuthStatus";
import { UrlContext } from "../components/UrlProvider";

export const CreateTranscriptPage = () => {
  const navigate = useNavigate();
  const { url, setUrl } = useContext(UrlContext);
  const [loading, setLoading] = useState(false);
  const { config } = useConfig();

  const requestContent = async (
    variant: Variant,
    instructions: string,
    video_id: string
  ) => {
    try {
      const id = await createContent({
        ...config,
        variant,
        instructions,
        video_id,
      });
      return id;
    } catch (err) {
      alert(`Unable to process given video, try another one. (Error: ${err})`);
    }
  };

  const getTranscript = async () => {
    try {
      const transcriptId = await createTranscript({
        url: url,
        sourceLang: config.sourceLang,
      });

      return transcriptId;
    } catch (err) {
      alert(
        `Videoinhalt nicht erkannt. Stimmt die Spracheinstellung? (Fehler: ${err})`
      );
    }
  };

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const transcriptId = await getTranscript();
      const id = await requestContent("blog", "", transcriptId); // TODO remove or implement instructions
      navigate(`/blog/${id}`);
    } catch (err) {
      alert(`Video konnte nicht verarbeitet werden (Fehler: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="sectionSize bg-secondary align">
      <h2 className="secondaryTitle bg-underline3 bg-100%">
        Artikel erstellen
      </h2>
      <YoutubeVideoComponent onSubmit={onSubmit} loading={loading} />
      <div className="mt-20">
        <AuthStatus />
      </div>
    </section>
  );
};

import { useEffect, useState } from "react";

export const useAsyncMemo = <T>(
  promiseFn: () => Promise<T>
): [boolean, T | undefined] => {
  const [result, setResult] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let active = true;
    setLoading(true);
    load();
    return () => {
      active = false;
    };

    async function load() {
      setResult(undefined); // this is optional
      const res = await promiseFn();
      if (!active) {
        return;
      }
      setResult(res);
      setLoading(false);
    }
    // TODO: Do we need to add promiseFn to the dependency array?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [loading, result];
};

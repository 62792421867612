import React from "react";

export interface CustomSelectOption<T> {
  value: T;
  label: string;
}

interface CustomSelectProps<T> {
  options: CustomSelectOption<T>[];
  onSelect: (value: T) => any;
  value: T;
}

function CustomSelect<T extends string>({
  options,
  value,
  onSelect,
}: CustomSelectProps<T>) {
  return (
    <div className="relative">
      <select
        value={value}
        className="block text-white font-bold appearance-none w-full bg-[00005D] border border-purple-900 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        id="select"
        onChange={(e) => onSelect(e.target.value as T)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M6.293 9.293a1 1 0 011.414 0L10 10.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
}

export default CustomSelect;

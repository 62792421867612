import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffect, useState } from "react";
import { getVoucherUsesLeft } from "../utils/voucherUsesLeft";

export const AuthStatus = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [usesLeft, setUsesLeft] = useState<number | null>(null);

  useEffect(() => {
    if (!auth.jwt) return;
    getVoucherUsesLeft(auth.voucher).then((res) => setUsesLeft(res));
  }, [auth.jwt, auth.voucher]);
  if (!auth.jwt) {
    return null;
  }

  return (
    <div className="text-gray-600">
      <p>
        {" "}
        Dein Guthaben Code:{" "}
        <strong className="text-gray-800">{auth.voucher}</strong>
      </p>
      <p className="mt-2">
        Das restliche Guthaben beträgt{" "}
        <strong className="text-gray-800">{usesLeft} Artikel.</strong>
      </p>
      <div className="flex justify-center">
        <button
          className="font-montserrat mt-4 hover:bg-white px-6 py-4 border-2 border-black border-solid rounded-lg"
          onClick={() => {
            auth.signOut();
            navigate("/");
          }}
        >
          Abmelden
        </button>
      </div>
    </div>
  );
};

import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { Router } from "./Router";

export function App() {
  return (
    <>
      <Navbar />
      <Router />
      <Footer />
    </>
  );
}

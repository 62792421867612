import React, { useEffect, useState } from "react";
import { clearToken, supabase, updateToken } from "../utils/supabase_instance";

export interface AuthContextType {
  jwt: string | null;
  voucher: string | null;
  signIn: (voucher: string) => Promise<void>;
  signOut: () => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [jwt, setJwt] = React.useState<any>(localStorage.getItem("v2ct"));
  let [voucher, setVoucher] = React.useState<any>(localStorage.getItem("v2cv"));

  useEffect(() => {
    const storedJwt = localStorage.getItem("v2ct");
    const storedVoucher = localStorage.getItem("v2cv");

    if (jwt === null && storedJwt !== null) {
      setJwt(storedJwt);
    }

    if (voucher === null && storedVoucher !== null) {
      setVoucher(storedVoucher);
    }

    if (jwt !== null) {
      updateToken(jwt);
    }
  }, []);

  let signIn = async (voucher: string) => {
    if (!jwt) {
      const { error, data } = await supabase.functions.invoke("getToken", {
        body: { voucher_id: voucher },
      });

      if (error) {
        throw Error(error);
      }

      setJwt(data.jwt);
      localStorage.setItem("v2ct", data.jwt);
      localStorage.setItem("v2cv", data.voucherId);
      setVoucher(data.voucherId);
      updateToken(data.jwt);
    }
  };

  let signOut = () => {
    clearToken();
    setVoucher(null);
    setJwt(null);
    localStorage.removeItem("v2ct");
    localStorage.removeItem("v2cv");
    window.location.reload();
  };

  let value = { jwt, signIn, signOut, voucher };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

"use client";
import Loading from "../components/SvgComps/loading";
import { Toggle } from "./Toggle";
import CustomSelect, { CustomSelectOption } from "./CustomSelect";
import LanguageSwitcher from "./LanguageSwitcher";
import { Tone } from "../types/enums";
import { useConfig } from "../hooks/useConfig";
import { useContext } from "react";
import { UrlContext } from "./UrlProvider";

const ToneOptions: CustomSelectOption<Tone>[] = [
  { label: "neutral", value: "neutral" },
  { label: "locker", value: "casual" },
  { label: "verspielt", value: "playful" },
  { label: "mitreißend", value: "engaging" },
  { label: "inspirierend", value: "inspirational" },
  { label: "humorvoll", value: "humorous" },
  { label: "formell", value: "formal" },
  { label: "informativ", value: "informative" },
  { label: "akademisch", value: "academic" },
  { label: "technisch", value: "technical" },
];

type Props = {
  onSubmit: () => any;
  loading: boolean;
};

export const YoutubeVideoComponent = ({ onSubmit, loading }: Props) => {
  const { config, setConfigByKey } = useConfig();
  const { url, setUrl } = useContext(UrlContext);

  return (
    <>
      <div className="max-w-[600px] w-full bg-secondary rounded-md shadow-2xl p-[16px] flex flex-col gap-[16px] animate-fadeIn">
        <div>
          <label className="block mb-2 text-sm font-medium">
            Aus welchem Video möchtest du einen Artikel erstellen?
          </label>
          <input
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            type="text"
            id="url"
            className="border text-sm rounded-lg block w-full p-2.5 border-gray-600 placeholder-gray-400  focus:ring-coral-500 focus:border-coral-500"
            placeholder="https://www.youtube.com/watch?v=TX9qSaGXFyg"
            required
          />
        </div>
        <button
          onClick={!loading ? onSubmit : undefined}
          type="button"
          className={`w-full text-white h-[40px] bg-gradient-to-br bg-black hover:bg-[00005D] focus:ring-4 focus:outline-none focus:ring-coral-300 dark:focus:ring-coral-800 font-medium rounded-lg text-sm flex  items-center justify-center text-center mr-2 mb-2`}
        >
          {!loading ? "Artikel generieren" : <Loading />}
        </button>

        <p>
          {loading
            ? "Pro 10 Minuten Video dauert die Generierung etwa eine Minute. Dieses Fenster bitte nicht schließen. Du wirst automatisch auf den generierten Artikel weitergeleitet."
            : null}
        </p>
      </div>
      <div className="flex flex-col gap-[8px] items-center max-w-[600px] w-full mt-5">
        <h2 className="text-[20px] font-semibold ">Optionen</h2>
        <div className="flex gap-[8px] w-full flex-col">
          <div className="max-w-[600px] w-full bg-secondary rounded-md shadow-2xl p-[16px] flex flex-col gap-[16px] animate-fadeIn">
            <div>
              <label className="block mb-2 text-sm font-medium">
                Sprache des Videos
              </label>
              <LanguageSwitcher
                value={config.sourceLang}
                onChange={(v) => setConfigByKey("sourceLang", v)}
              ></LanguageSwitcher>
              <br></br>
              <label className="block mb-2 text-sm font-medium">
                In welcher Sprache soll der Artikel verfasst sein?
              </label>
              <LanguageSwitcher
                value={config.targetLang}
                onChange={(v) => setConfigByKey("targetLang", v)}
              ></LanguageSwitcher>

              <br></br>
              <label className="block mb-2 text-sm font-medium">
                Wie sollen Lesende angesprochen werden?
              </label>
              <Toggle<"polite", "general">
                value={config.speechForm}
                onChange={(v) => setConfigByKey("speechForm", v)}
                options={["polite", "general"]}
                optionLabels={["Siezen", "Duzen"]}
              />
              <br />

              <div className="mb-4">
                <label
                  className="block mb-2 text-sm font-medium"
                  htmlFor="select"
                >
                  Tonfall des Textes
                </label>
                <CustomSelect<Tone>
                  options={ToneOptions}
                  onSelect={(v) => setConfigByKey("tone", v)}
                  value={config.tone}
                ></CustomSelect>
              </div>

              <div className="mb-4">
                <label
                  className="block mb-2 text-sm font-medium"
                  htmlFor="select"
                >
                  Soll genderneutrale Sprache verwendet werden?
                </label>
                <Toggle<"strict", "not_important">
                  value={config.enforce_gender_neutral_language}
                  onChange={(v) =>
                    setConfigByKey("enforce_gender_neutral_language", v)
                  }
                  options={["strict", "not_important"]}
                  optionLabels={["Ja", "Nicht unbedingt"]}
                />
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

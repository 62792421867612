import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import rehype from "rehype-raw";
import CopyComp from "@/app/components/Copy";
import { supabase } from "../utils/supabase_instance";
import { useAsyncMemo } from "@/app/hooks/useAsyncMemo";
import Loading from "@/app/components/SvgComps/loading";
import { useParams } from "react-router-dom";
import { useRef } from "react";

async function getBlog(id: string) {
  const { error, data } = await supabase.functions.invoke("getBlog", {
    body: { blog_id: id },
  });

  return data?.content || undefined;
}

export const BlogPage = () => {
  const params = useParams();
  const [loading, blog] = useAsyncMemo(() => getBlog(params?.id));

  if (!blog) {
    return (
      <div className="w-screen h-screen flex justify-center">
        <div className="w-full flex items-center flex-col max-w-[900px] gap-[16px] mt-[32px]">
          <div>
            Unter diesem Link gibt es keinen Artikel oder du hast keinen Zugriff
            auf diesen Artikel.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen flex justify-center">
      <div className="w-full flex items-center flex-col max-w-[900px] gap-[16px] mt-[32px]">
        <div className="flex justify-between w-full">
          <h1 className="text-[28px] font-semibold text-center">
            Dein Artikel ist fertig
          </h1>
        </div>

        <div
          style={{ overflow: "scroll" }}
          className="w-full cursor-pointer bg-[#E6EFEE] rounded-md shadow-lg p-[16px] m-[16px] flex flex-col gap-[16px] animate-fadeIn whitespace-break-spaces"
        >
          {loading && <Loading />}
          {blog && !loading ? (
            <>
              <CopyComp text={blog} />
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehype]}
                className={`markdownHolder`}
              >
                {blog}
              </ReactMarkdown>
            </>
          ) : (
            <>Artikel nicht gefunden.</>
          )}
        </div>
        <div className="w-full  rounded-md shadow-lg p-[16px] m-[16px] flex flex-col gap-[16px] animate-fadeIn whitespace-break-spaces">
          Diesen Artikel kannst du in Zukunft immer unter{" "}
          <a href="https://cleverpenman.com/blog/{params.id}">
            cleverpenman.com/blog/{params.id}
          </a>{" "}
          abrufen, wenn du dich mit deiner ID authentifiziert hast.
        </div>
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useMemo } from "react";
import { ConfigType } from "../types/content";

export type ConfigContextType = {
  config: ConfigType;
  setConfigByKey: <T extends keyof ConfigType>(
    key: T,
    config: ConfigType[T]
  ) => void;
};
export const ConfigContext = React.createContext<ConfigContextType>(null!);

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = React.useState<ConfigType>({
    sourceLang: "de",
    targetLang: "de",
    instructions: "",
    speechForm: "polite",
    tone: "neutral",
    enforce_gender_neutral_language: "not_important",
  });

  const setConfigByKey = useCallback(
    (key: keyof ConfigType, value: any) => {
      setConfig({ ...config, [key]: value });
    },
    [config]
  );

  const value = useMemo(
    () => ({
      config,
      setConfigByKey,
    }),
    [config, setConfigByKey]
  );
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

import { Language } from "../types/enums";
import { Toggle } from "./Toggle";

export const LanguageSwitcher: React.FC<{
  value: Language;
  onChange: (Language) => any;
}> = ({ value, onChange }) => {
  return (
    <Toggle<"en", "de">
      value={value}
      onChange={onChange}
      options={["en", "de"]}
      optionLabels={["Englisch", "Deutsch"]}
    />
  );
};

export default LanguageSwitcher;

import { Link } from "react-router-dom";

export const Footer = () => {
  const primaryItems = [
    { name: "Guthaben aufladen", href: "https://buy.cleverpenman.com/" },
    {
      name: "Blogartikel aus Video generieren",
      href: "/articles/wie-man-aus-einem-video-einen-blog-artikel-generiert",
    },
    {
      name: "Social Media Post aus Video generieren",
      href: "/articles/wie-man-aus-einem-video-einen-blog-artikel-generiert",
    },
  ];

  const secondaryItems = [
    {
      name: "Impressum",
      href: "/impressum",
    },
    {
      name: "Datenschutz",
      href: "/datenschutz",
    },
  ];

  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mb-6 md:mb-0 md:w-1/3">
            <img
              src={
                new URL(
                  "/public/cleverpenman-transparent.png?as=webp&width=400",
                  import.meta.url
                ).href
              }
              alt="CleverPenMan Logo"
              className="h-12 mb-4"
            />
            <p className="text-sm">
              CleverPenMan hilft dir, aus Videos schnell und einfach Blogartikel
              und Social Media Posts zu generieren.
            </p>
          </div>

          <div className="mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Wichtige Themen</h3>
            <ul>
              {primaryItems.map((item, index) => (
                <li key={index} className="mb-2 list-none">
                  <Link to={item.href} className="hover:text-gray-300">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Über uns</h3>
            <ul>
              {secondaryItems.map((item, index) => (
                <li key={index} className="mb-2 list-none">
                  <Link to={item.href} className="hover:text-gray-300">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

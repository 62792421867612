import { Route, Routes } from "react-router-dom";
import { CreateTranscriptPage } from "./pages/CreateTranscriptPage";
import { BlogPage } from "./pages/BlogPage";
import { Layout } from "./pages/Layout";
import { LoginPage } from "./pages/LoginPage";
import { RequireAuth } from "./components/RequireAuth";
import { LandingPage } from "./pages/LandingPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { articles } from "./pages/articles/articles";
import Imprint from "./pages/Imprint";
import DataProtection from "./pages/DataProtection";

export const Router = () => (
  <Routes>
    <Route path="/" Component={Layout}>
      <Route index Component={LandingPage} />
      {articles.map((article) => (
        <Route
          key={article.url}
          path={article.url}
          element={<article.component />}
        />
      ))}
      <Route path="login" Component={LoginPage} />
      <Route
        path="create-transcript"
        element={
          <RequireAuth>
            <CreateTranscriptPage />
          </RequireAuth>
        }
      />
      <Route
        path="blog/:id"
        element={
          <RequireAuth>
            <BlogPage />
          </RequireAuth>
        }
      />
      <Route path="impressum" element={<Imprint />} />
      <Route path="datenschutz" element={<DataProtection />} />
      <Route path="*" Component={NotFoundPage} />
    </Route>
  </Routes>
);

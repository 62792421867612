import { ContentGenerationOptions, ContentPostPayload } from "../types/content";
import { supabase } from "./supabase_instance";

export const createContent = async ({
  video_id,
  variant,
  instructions,
  speechForm,
  tone,
  sourceLang,
  targetLang,
  enforce_gender_neutral_language,
}: ContentPostPayload) => {
  try {
    const { data, error } = await supabase.functions.invoke("createContent", {
      body: {
        video_id,
        variant,
        instructions,
        speechForm,
        tone,
        sourceLang,
        targetLang,
        enforce_gender_neutral_language,
      },
    });
    if (error) throw error;
    const { id } = data;
    return id;
  } catch (err) {
    alert("Something went wrong: " + err);
  }
  return null;
};

import { supabase } from "../utils/supabase_instance";

export async function getVoucherUsesLeft(voucher) {
  const { error, data } = await supabase.functions.invoke("getVoucher", {
    body: { voucher_id: voucher },
  });

  if (error) {
    console.error(error);
  }

  if (typeof data.voucher.uses_left !== "number") {
    throw new Error("expected voucher uses to be number");
  }

  return data.voucher.uses_left;
}

import type { SitemapItemLoose } from "sitemap";
import { HowToArticle } from "./HowToArticle";

export const articles: (SitemapItemLoose & {
  component: React.ComponentType;
})[] = [
  {
    url: "/articles/wie-man-aus-einem-video-einen-blog-artikel-generiert",
    component: HowToArticle,
  },
];

import { Link, Outlet, useNavigate } from "react-router-dom";

export const Layout = () => {
  return (
    <div className="md:mt-0 bg-secondary">
      {/* <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blogs">Blogs</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav> */}
      <Outlet />
    </div>
  );
};
